import * as Highcharts from 'highcharts';
import 'highcharts/highcharts-more';
import 'highcharts/modules/solid-gauge';

import * as gaugesConfig from './config.json';
import { getKeyTime, getChartById } from '../../../utils';

import { Aeroval } from '../../../types/global';
declare var aeroval: Aeroval;


export function initGauges(div: string): void {
	const nCol = 2;
	const w = 12 / nCol;
	var h = "<div class='row'>";
	for (let gauge in gaugesConfig) {
		if (gauge == 'default') continue;
		h += `<div class='gauge col-md-${w} col-3' id='gauge-${gauge}' style='width: 50%; height: 100px' style='border: 1px solid red'></div>`;
	}
	h += '</div>';
	const gauges = document.getElementById(div);
	if (gauges) gauges.innerHTML = h;
}


export function plotGauges(): void {
	for (let gauge in gaugesConfig) {
		if (gauge == 'default') continue;
		const div = `gauge-${gauge}`;
		const unit = aeroval.statistics[gauge].unit == '%' ? '%' : '';

		const series = [
			{
				name: aeroval.statistics[gauge].name,
				data: [null],
				dataLabels: {
					format: "<div style='text-align:center'>" + "<span style='font-size:13px'>{y:.2f}</span><br/>" + '</div>',
					y: 1,
				},
			},
		] as any;

		Highcharts.chart({
			chart: {
				renderTo: div,
				type: 'solidgauge'
			},
			tooltip: {
				enabled: false,
			},
			pane: {
				center: ['50%', '85%'],
				size: '110%',
				startAngle: -90,
				endAngle: 90,
				background: [
					{
						backgroundColor: 'transparent' as Highcharts.ColorString,
						innerRadius: '60%',
						outerRadius: '100%',
						shape: 'arc',
					},
				],
			},
			title: {
				text: '',
			},
			exporting: {
				enabled: false,
			},
			yAxis: {
				stops: (gaugesConfig as any)[gauge],
				min: aeroval.statistics[gauge].scale[0],
				max: aeroval.statistics[gauge].scale[aeroval.statistics[gauge].scale.length - 1],
				lineWidth: 0,
				tickWidth: 0,
				minorTickInterval: undefined,
				tickAmount: 2,
				title: {
					text: `${aeroval.statistics[gauge].name} ${unit}`,
					y: -35,
				},
				labels: {
					y: 15,
				},
			},
			plotOptions: {
				solidgauge: {
					dataLabels: {
						borderWidth: 0,
						useHTML: true,
					},
				},
			},
			series,
		});
	}
}

export function updateGauges(data: Aeroval['data']['hm'], hover: any): void {
	if (data && typeof data[hover.var][hover.obs][hover.lay][hover.mod] != 'undefined') {
		//time selection
		const keyTime = getKeyTime(aeroval.time, aeroval.season);
		const subData = data[hover.var][hover.obs][hover.lay][hover.mod][hover.modVar][aeroval.region][keyTime];
		if (subData) {
			for (let key in gaugesConfig) {
				if (key == 'default') continue;
				if (key == 'nrms' && subData['rms'] && subData['refdata_mean'] !== 0) {
					var value = (subData['rms'] as number) / (subData['refdata_mean'] as number);
				} else {
					value = subData[key] as number;
				}
				if (aeroval.statistics[key].unit == '%') {
					value = value * 100;
				}
				//update series
				const gaugeChart = getChartById(`gauge-${key}`);
				if (gaugeChart) {
					gaugeChart.series[0].setData([value], true);
				}
			}
		}
	}
}
