import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faInfoCircle);

const infoCircle = icon({ prefix: 'fas', iconName: 'info-circle' });

import * as Highcharts from 'highcharts';
import 'highcharts/modules/exporting';
import 'highcharts/modules/export-data';
import 'highcharts/modules/offline-exporting';
import '../../highcharts-options';

import { Popover } from 'bootstrap';

import * as utils from '../../../utils';
import { plotScatterPlotMultiModel } from '../scatter-plot-multi-model';

import chartsStyle from '../../../../public/config/charts-style.json';

import { Aeroval } from '../../../types/global';
import { TSData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotTimeSeriesMultiModel(data: TSData, div: string, plotScat: boolean = false): void {
	plotTimeSeries(data, div, plotScat);
}

function plotTimeSeries(data: TSData, div: string, plotScat: boolean = false): void {
	const mainModelData = data[aeroval.model.dir];

	div = typeof aeroval.experiment.additional_ts_tabs != 'undefined' ? 'nav-ts' : 'intercomp-ts';
	const otherDivs = typeof aeroval.experiment.additional_ts_tabs != 'undefined' ? aeroval.experiment.additional_ts_tabs : [];
	//filter models: should be also in the menu
	const models = utils.getModels();

	// set legend visibility depending on scatterplot
	const expScatterplot = aeroval.experiment.scatterplot;
	const showModInLegend = expScatterplot === false ? true : false;

	// time series
	const series = [] as any;

	// get time series for all primary and secondary frequencies
	var frequencies = utils.getOrderedFrequencies();
	// remove yearly if single year
	if (frequencies.includes('yearly') && (mainModelData['yearly_date']?.length ?? 0) <= 1) {
		frequencies = frequencies.filter((item) => item !== 'yearly');
	}
	frequencies = utils.filterFrequencies(frequencies, aeroval.frequency)
	const whichFrequencies: string[] = ['primary', 'secondary'];
	const minN = Math.min(frequencies.length, whichFrequencies.length);

	for (let i: number = 0; i < minN; i++) {
		const freq = frequencies[i];
		const whichFreq = whichFrequencies[i] as 'primary' | 'secondary';
		const opacity = whichFreq === 'primary' ? 1 : 0.2;
		const zIndex = whichFreq === 'primary' ? 5 : 3;
		const zIndexObs = whichFreq === 'primary' ? 6 : 4;
		const lineWidth = chartsStyle['intercomp-ts'][`obs-${whichFreq}`].lineWidth * Number(aeroval.settings.lineWidthFactor);
		const markerSize = chartsStyle['intercomp-ts'][`obs-${whichFreq}`].markerSize * Number(aeroval.settings.lineWidthFactor);

		const keyDate = `${freq}_date` as keyof TSData[keyof TSData];
		const keyObs = `${freq}_obs` as keyof TSData[keyof TSData];
		const keyMod = `${freq}_mod` as keyof TSData[keyof TSData];

		// loop over different models
		for (let j: number = 0; j < models.length; j++) {
			const model = models[j]
			// subselect model data
			const modelData = data[model];
			if (!modelData) continue;

			var obsData: [number, number][] = [];
			var modData: [number, number][] = [];

			const dateArray = modelData[keyDate] as number[];
			const obsArray = modelData[keyObs] as number[];
			const modArray = modelData[keyMod] as number[];

			for (let k in obsArray) {
				obsData.push([dateArray[k], obsArray[k]]);
				modData.push([dateArray[k], modArray[k]]);
			}

			const name = `Observation (${freq})`;

			// change which freq if secondary frequency and the primary frequency is not visible
			let seriesVisibility: boolean = aeroval.seriesVisibility[`Observation (${frequencies[i - 1]})`];
			const whichStyle = (whichFreq === 'secondary' && seriesVisibility === false) ? 'primary' as 'primary' | 'secondary' : whichFreq;
			// set visibility
			const obsVisible = typeof aeroval.seriesVisibility[name] != 'undefined' ? aeroval.seriesVisibility[name] : true;

			// first, push obs corresponding to the selected model
			if (model == aeroval.model.dir) {
				series.push({
					name,
					id: `obs-${whichFreq}`,
					showInLegend: true,
					data: obsData,
					color: aeroval.settings.theme === "dark" ? chartsStyle['intercomp-ts'][`obs-${whichStyle}`].colorDarkMode : chartsStyle['intercomp-ts'][`obs-${whichStyle}`].color,
					lineWidth: lineWidth,
					opacity: 1,
					marker: {
						enabledThreshold: 2,
						radius: markerSize,
					},
					visible: obsVisible,
					enableMouseTracking: true,
					zIndex: zIndexObs,
					label: {
						enabled: false,
						connectorAllowed: true,
					},
					weight: 2
				});
			}

			//then, push model data
			const highchartsColor = utils.highchartsPalette(j);
			const modelStyle = utils.getModelStyle(model);
			const modVisible = typeof aeroval.seriesVisibility[model] != 'undefined' ? aeroval.seriesVisibility[model] : true;
			const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;
			const modelWeight = modelStyle?.weight ? modelStyle.weight : 1;
			const markerSizeModel = modelWeight * chartsStyle['overall-ts']['mod'].markerSize * Number(aeroval.settings.lineWidthFactor);
			const lineWidthModel = modelWeight * chartsStyle['overall-ts']['mod'].lineWidth * Number(aeroval.settings.lineWidthFactor);
			const labelEnabled = whichFreq == 'secondary' ? false : true;

			series.push({
				zIndex: zIndex,
				name: model,
				id: `mod-${freq}-${model}`,
				showInLegend: showModInLegend,
				visible: modVisible,
				data: modData,
				color: colorModel,
				opacity,
				marker: {
					enabledThreshold: 1,
					radius: markerSizeModel,
					symbol: 'circle',
				},
				lineWidth: lineWidthModel,
				enableMouseTracking: true,
				label: {
					enabled: labelEnabled,
				}
			});
		}
	}

	// add all models series
	for (let i in frequencies.slice(0, minN)) {
		const freq = frequencies[i];
		const whichFreq = whichFrequencies[i] as 'primary' | 'secondary';
		const name = `All Models (${freq})`;
		const id = `mod-${whichFreq}-*`;
		const opacity = whichFreq === 'primary' ? 1 : 0.2;
		const lineWidth = chartsStyle['intercomp-ts'][`mod-${whichFreq}`].lineWidth * Number(aeroval.settings.lineWidthFactor);
		const markerSize = chartsStyle['intercomp-ts'][`mod-${whichFreq}`].markerSize * Number(aeroval.settings.lineWidthFactor);

		series.push({
			name,
			id,
			showInLegend: true,
			color: utils.highchartsPalette(0),
			opacity,
			marker: {
				enabledThreshold: 1,
				radius: markerSize,
				symbol: 'circle',
			},
			lineWidth,
		});
	}

	// set units
	const axisUnit = data[aeroval.model.dir].obs_unit != '1' ? ` (${data[aeroval.model.dir].obs_unit})` : `1`;
	// set axis limits
	const yAxisSoftMin = aeroval.ranges[aeroval.parameter.dir].scale[0];
	const xAxisMin = aeroval?.dateMin?.valueOf();
	const xAxisMax = aeroval?.dateMax?.valueOf();
	//set title
	const titleText = getTitle();
	const syncing = false;
	const gridLineWidth = Number(aeroval.settings.verticalGridLines) ? 2 : 0;

	Highcharts.chart(
		{
			title: {
				text: titleText,
			},
			subtitle: {
				text: `${aeroval.observation} - intercomparison`,
			},
			chart: {
				renderTo: div,
				type: aeroval.settings.lineStyle,
				zooming: {
					type: 'x',
				},
				resetZoomButton: {
					position: {
						align: 'center',
						verticalAlign: 'top',
					},
				},
			},
			boost: {
				enabled: false
			},
			tooltip: {
				shared: true,
				valueDecimals: 3,
			},
			yAxis: {
				title: {
					text: `${aeroval.parameter.name}${axisUnit}`
				},
				softMin: yAxisSoftMin,
				type: aeroval.axisType,
			},
			xAxis: {
				type: 'datetime',
				title: {
					text: 'Time',
				},
				gridLineWidth: gridLineWidth,
				plotBands: aeroval.highlightedSeasons,
				min: xAxisMin,
				max: xAxisMax,
				events: {
					setExtremes: function (event) {
						const scatterplot = aeroval.cfg.webdisp_opts.hide_charts?.includes('scatterplot') ? false : true;
						setZoomState(event, scatterplot);
						if (!syncing) utils.syncZoom(otherDivs, event);
					},
				},
			},
			plotOptions: {
				series: {
					turboThreshold: 0,
					events: {
						legendItemClick: function () {
							utils.clickOnLegendItem(this);
						},
					},
					states: {
						inactive: {
							enabled: false,
						},
					}
				},
			},
			legend: {
				floating: true,
				verticalAlign: 'top',
				y: 50,
				layout: 'vertical',
				align: 'right',
			},
			series
		},
		function (chart) {
			addInfo(data);
			if (aeroval.statistic.dir.includes('trend')) {
				addTrendMultiModel(div);
			}
			// set initial zoom level
			if (aeroval?.zoom?.state === true) {
				chart.xAxis[0].setExtremes(aeroval.zoom.min, aeroval.zoom.max);
				chart.showResetZoom();
			}

			if (plotScat) {
				plotScatterPlotMultiModel('scat');
			}
			utils.setManualExtremes(chart, ['y'])
		}
	);
}

export function cleanTrendMultiModel(id: string): void {
	const tsChart = utils.getChartById(id);
	const tsSeries = tsChart?.series;
	if (!tsChart || !tsSeries) return;

	const idsToRemove = [];
	for (const series of tsSeries) {
		const id = series.userOptions.id;
		if (id != undefined && id.includes('trend')) {
			idsToRemove.push(id);
		}
	}

	//if still visualize a trend, just delete previous trends. Otherwise, plotTS
	if (aeroval.statistic.dir.includes('trend')) {
		for (const idToRemove of idsToRemove) {
			tsChart.get(idToRemove)?.remove();
		}
	} else if (idsToRemove.length > 0 && aeroval.data.ts) {
		plotTimeSeries(aeroval.data.ts, 'ts');
	}
}

async function getMap(observation: string, parameter: string, layer: string, model: string): Promise<Aeroval["MapData"] | null> {
	try {
		const url = `${window.API_ROOT}/map/${aeroval.project}/${aeroval.experiment.name}/${observation}/${parameter}/${layer}/${model}/${parameter}/${aeroval.time.replaceAll('/', '')}${window.DATA_PATH ? `?data_path=${window.DATA_PATH}` : ''}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json'
			}
		});

		if (!response.ok) {
			console.error('Network response was not ok');
			return null;
		}

		const data = await response.json();
		return data; // Return the fetched data
	} catch (error) {
		console.error('Error:', error);
		return null; // Return null on error
	}
}

export async function addTrendMultiModel(id: string): Promise<void> {
	const tsChart = utils.getChartById(id);
	const data = aeroval.data.ts;
	if (!data || !tsChart) return;

	//first of all, remove the trends that are already plotted
	cleanTrendMultiModel(id);

	const models = utils.getModels();
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);
	const isRegion = Object.keys(aeroval.regions).includes(aeroval.station)

	for (let m: number = 0; m < models.length; m++) {
		const model = models[m];
		const modelData = data[model];
		if (!modelData) continue;

		// colors
		const highchartsColor = utils.highchartsPalette(m);
		const modelStyle = utils.getModelStyle(model);
		const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;

		//make lines lighter, thinner, markers smaller
		const tsSeries = tsChart.series;
		for (let i in tsSeries) {
			const tsID = tsSeries[i].userOptions.id;
			if (tsID != undefined && tsID.includes('trend')) {
				//idsToRemove.push(id)
			} else {
				tsChart.series[i].update(
					{
						lineWidth: 1,
						marker: {
							radius: 2,
						},
						opacity: 0.4,
					} as any,
					false
				);
			}
		}

		//check if the trend is composed of two parameters (obs and mod)
		if (aeroval.statistic.dir.includes('/')) {
			const stat1 = `${aeroval.statistic.dir.split('/')[0]}_${aeroval.statistic.dir.split('_')[1]}`;
			const stat2 = aeroval.statistic.dir.split('/')[1];
			var stats = [stat1, stat2];
		} else {
			stats = [aeroval.statistic.dir];
		}

		for (let j in stats) {
			// add obs trend only when reading first model
			if (stats[j] == 'obs_trend' && m > 0) continue;
			if (stats[j] == 'obs_trend') {
				var colorSeries = aeroval.settings.theme === "dark" ? chartsStyle['intercomp-ts'][`obs-primary`].colorDarkMode : chartsStyle['intercomp-ts'][`obs-primary`].color
			} else {
				colorSeries = colorModel
			}


			const lineWidth = 3;

			if (isRegion && aeroval.data.reg_stat) {
				//if the station is a region, read value from aeroval.sub_hmData
				const modVar = Object.keys(aeroval.data.reg_stat[model])[0];
				var trendStat = aeroval.data.reg_stat[model][modVar][aeroval.station][keyTime] as any;
			} else {
				//else, read it fom the map
				const modelMap = await getMap(aeroval.observation, aeroval.parameter.dir, aeroval.layer, model)
				if (!modelMap) continue;
				for (const station of modelMap) {
					if (aeroval.station == station.station_name) {
						var trendStat = station[aeroval.frequency]?.[keyTime] as any;
						break;
					}
				}
			}

			//compute trend
			if (trendStat?.obs_trend && modelData['monthly_date']) {
				const trend = trendStat[stats[j]];

				//creates x axis for monthly time series
				const xArray: number[] = [];
				const y0 = trend.period.split('-')[0];
				const y1 = trend.period.split('-')[1];
				for (let date of modelData['monthly_date']) {
					if (date >= new Date(y0, 0, 1).valueOf() && date < new Date(y1, 0, 1).valueOf()) {
						xArray.push(date);
					}
				}

				// compute trend equation
				if (trend['slp_' + y0] != null) {
					const b = trend['reg0_' + y0];
					const a = (trend['slp_' + y0] / (365 * 24 * 60 * 60 * 1000)) * b;
					const relativeTrend = trend['slp_' + y0].toFixed(2);
					const errorTrend = trend['slp_' + y0 + '_err'].toFixed(2);
					const pValTrend = trend['pval'].toFixed(2);
					const strTrend = `${(relativeTrend < 0 ? '' : '+')}${relativeTrend}+/-${errorTrend}%/yr; p-val: ${pValTrend}`;
					const regTrend = xArray.map(function (x) {
						return ((x - xArray[0]) * (a as number)) / 100 + b;
					});

					const trendData = [];
					for (let k = 0; k < xArray.length; k++) {
						trendData.push([xArray[k], regTrend[k]]);
					}

					//plot line as dashed line if non significant
					const dashStyle = trend['pval'] > 0.1 ? 'Dash' : 'Solid';
					const trendSeriesId = stats[j] == 'obs_trend' ? 'trend-obs' : `trend-${model}-${j}`
					if (a != null) {
						const trendsSeries = {
							type: 'line',
							id: trendSeriesId,
							name: strTrend,
							data: trendData,
							color: colorSeries,
							dashStyle,
							lineWidth,
							marker: {
								enabled: false,
								radius: 1.5,
							},
							enableMouseTracking: false,
							zIndex: 10,
						} as any;
						//add it to the chart
						tsChart.addSeries(trendsSeries, false);

						//plot yearly data used for trends Computation
						const trendDate = JSON.parse(trend.data) as any[];
						const yearlyData: [number, number][] = [];
						for (let key in trendDate) {
							yearlyData.push([parseInt(key), trendDate[key] as number]);
						}

						const trendDataSeriesId = stats[j] == 'obs_trend' ? 'trend-data-obs' : `trend-data-${model}-${j}`
						const yearlySeries = {
							type: 'line',
							id: trendDataSeriesId,
							data: yearlyData,
							color: colorSeries,
							opacity: 0.9,
							lineWidth: 0,
							marker: {
								enabled: true,
								radius: 4,
								symbol: 'circle',
							},
							showInLegend: false,
							enableMouseTracking: false,
							zIndex: 5,
							linkedTo: trendSeriesId,
						};
						//add it to the chart
						tsChart.addSeries(yearlySeries as Highcharts.SeriesOptionsType, false);
					}
				}
			}
		}
	}
	tsChart.redraw();
}

function addInfo(data: TSData) {
	const mainModelData = data[aeroval.model.dir];
	if (!mainModelData) return;

	var info = {
		obs_unit: {
			title: 'Obs Unit',
			content: undefined,
		},
		mod_unit: {
			title: 'Mod Unit',
			content: undefined,
		},
		obs_freq_src: {
			title: 'Obs Freq',
			content: undefined,
		},
		mod_freq_src: {
			title: 'Mod Freq',
			content: undefined,
		},
		obs_revision: {
			title: 'Obs Revision',
			content: undefined,
		},
		pyaerocom_version: {
			title: 'Pyaerocom',
			content: undefined,
		},
	};

	const keys = Object.keys(mainModelData);
	var nInfo = 0;
	for (const tsKey of keys) {
		if (tsKey in info) {
			(info as any)[tsKey].content = (mainModelData as any)[tsKey];
			nInfo++;
		}
	}

	//push all the info fields into html
	var infoHTML = '<ul>';
	if (nInfo > 0) {
		for (let infoKey in info) {
			const thisInfo = (info as any)[infoKey];
			if (thisInfo['title'] && thisInfo['content']) {
				const iKey = keys.indexOf(infoKey);
				infoHTML += `<li class='infobox-${keys[iKey]}'><b>${thisInfo['title']}</b>: ${thisInfo['content'].toString().replace(/"/g, '')}</li>`
			}
		}
	} else {
		infoHTML += 'Information not available yet';
	}
	infoHTML += '</ul>';
	var h = `<a href="#" id="infoBox" data-toggle="popover" class="btn" title="Information" data-bs-content="${infoHTML}">${infoCircle.html}</a>`

	const tsInfo = document.getElementById('tsInfo');
	if (tsInfo instanceof HTMLElement) {
		tsInfo.innerHTML = h;
	}

	//enable popover
	const infoBox = document.querySelector('#infoBox');

	// Check if the element exists
	if (infoBox) {
		// Initialize popover
		new Popover(infoBox, {
			html: true,
			placement: 'bottom',
			trigger: 'focus',
		});
	}
}

function setZoomState(event: Highcharts.AxisSetExtremesEventObject, plotScat = true) {
	if (event.max && event.min) {
		aeroval.zoom = {
			state: true,
			min: event.min,
			max: event.max,
		};
	} else {
		aeroval.zoom = {
			state: false,
			min: undefined,
			max: undefined,
		};
	}

	if (plotScat) {
		plotScatterPlotMultiModel('scat');
	}
}

function getTitle(): string {
	var title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	// add station and time
	title += ` - ${aeroval.station} - ${aeroval.time}`;
	// add season
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}
