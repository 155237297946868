import Highcharts from 'highcharts';

/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function toggleVerticalGridLines(value: string): void {
	aeroval.settings.verticalGridLines = value;
	const gridLineWidth = Number(aeroval.settings.verticalGridLines) ? 2 : 0;

	// redraw all charts
	Highcharts.charts.forEach(function (chart) {
		if (chart && ((chart.options.xAxis as any)[0]?.type === 'datetime' || chart.options.chart?.renderTo === 'frcst')) {
			chart.update({
				xAxis: {
					gridLineWidth: gridLineWidth,

				},
			});
		}
	});
}
