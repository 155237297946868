const colormaps = {
	CAMS: [
		[0.0, [0.78, 0.918, 0.957]],
		[0.2, [0.616, 0.831, 0.886]],
		[0.4, [0.996, 0.961, 0.667]],
		[0.6, [0.953, 0.784, 0.282]],
		[0.8, [0.847, 0.384, 0.043]],
		[1.0, [0.529, 0.153, 0.086]],
	],
	Blues: [
		[0.0, [0.969, 0.984, 1.0]],
		[0.067, [0.916, 0.951, 0.983]],
		[0.133, [0.864, 0.917, 0.967]],
		[0.2, [0.814, 0.884, 0.95]],
		[0.267, [0.756, 0.85, 0.93]],
		[0.333, [0.672, 0.814, 0.901]],
		[0.4, [0.58, 0.77, 0.874]],
		[0.467, [0.473, 0.712, 0.851]],
		[0.533, [0.377, 0.653, 0.822]],
		[0.6, [0.291, 0.595, 0.789]],
		[0.667, [0.216, 0.529, 0.754]],
		[0.733, [0.147, 0.46, 0.719]],
		[0.8, [0.09, 0.393, 0.671]],
		[0.867, [0.038, 0.326, 0.618]],
		[0.933, [0.031, 0.257, 0.522]],
		[1.0, [0.031, 0.188, 0.42]],
	],
	BrBG: [
		[0.0, [0.329, 0.188, 0.02]],
		[0.067, [0.476, 0.275, 0.033]],
		[0.133, [0.616, 0.38, 0.085]],
		[0.2, [0.749, 0.506, 0.176]],
		[0.267, [0.833, 0.676, 0.386]],
		[0.333, [0.905, 0.81, 0.582]],
		[0.4, [0.965, 0.91, 0.765]],
		[0.467, [0.962, 0.944, 0.895]],
		[0.533, [0.901, 0.946, 0.94]],
		[0.6, [0.78, 0.918, 0.898]],
		[0.667, [0.595, 0.842, 0.804]],
		[0.733, [0.404, 0.733, 0.692]],
		[0.8, [0.208, 0.592, 0.561]],
		[0.867, [0.072, 0.464, 0.433]],
		[0.933, [0.003, 0.345, 0.308]],
		[1.0, [0.0, 0.235, 0.188]],
	],
	BuGn: [
		[0.0, [0.969, 0.988, 0.992]],
		[0.067, [0.931, 0.974, 0.984]],
		[0.133, [0.892, 0.958, 0.972]],
		[0.2, [0.839, 0.94, 0.932]],
		[0.267, [0.773, 0.915, 0.887]],
		[0.333, [0.667, 0.873, 0.826]],
		[0.4, [0.56, 0.83, 0.759]],
		[0.467, [0.453, 0.784, 0.682]],
		[0.533, [0.361, 0.74, 0.595]],
		[0.6, [0.284, 0.698, 0.499]],
		[0.667, [0.216, 0.637, 0.399]],
		[0.733, [0.153, 0.563, 0.296]],
		[0.8, [0.082, 0.498, 0.231]],
		[0.867, [0.009, 0.435, 0.179]],
		[0.933, [0.0, 0.352, 0.141]],
		[1.0, [0.0, 0.267, 0.106]],
	],
	BuPu: [
		[0.0, [0.969, 0.988, 0.992]],
		[0.067, [0.921, 0.955, 0.973]],
		[0.133, [0.87, 0.919, 0.953]],
		[0.2, [0.801, 0.867, 0.924]],
		[0.267, [0.732, 0.815, 0.896]],
		[0.333, [0.663, 0.767, 0.871]],
		[0.4, [0.605, 0.707, 0.839]],
		[0.467, [0.568, 0.628, 0.797]],
		[0.533, [0.549, 0.543, 0.755]],
		[0.6, [0.549, 0.453, 0.711]],
		[0.667, [0.544, 0.365, 0.668]],
		[0.733, [0.535, 0.277, 0.626]],
		[0.8, [0.522, 0.176, 0.564]],
		[0.867, [0.508, 0.072, 0.495]],
		[0.933, [0.411, 0.031, 0.397]],
		[1.0, [0.302, 0.0, 0.294]],
	],
	CMRmap: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.08, 0.08, 0.267]],
		[0.133, [0.16, 0.15, 0.517]],
		[0.2, [0.24, 0.15, 0.65]],
		[0.267, [0.34, 0.157, 0.717]],
		[0.333, [0.5, 0.183, 0.583]],
		[0.4, [0.68, 0.21, 0.43]],
		[0.467, [0.893, 0.237, 0.243]],
		[0.533, [0.973, 0.317, 0.11]],
		[0.6, [0.92, 0.45, 0.03]],
		[0.667, [0.9, 0.583, 0.033]],
		[0.733, [0.9, 0.717, 0.087]],
		[0.8, [0.9, 0.81, 0.26]],
		[0.867, [0.9, 0.89, 0.473]],
		[0.933, [0.947, 0.947, 0.733]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	GnBu: [
		[0.0, [0.969, 0.988, 0.941]],
		[0.067, [0.921, 0.969, 0.897]],
		[0.133, [0.873, 0.951, 0.853]],
		[0.2, [0.831, 0.934, 0.807]],
		[0.267, [0.781, 0.914, 0.764]],
		[0.333, [0.706, 0.885, 0.731]],
		[0.4, [0.624, 0.853, 0.722]],
		[0.467, [0.529, 0.818, 0.753]],
		[0.533, [0.435, 0.774, 0.784]],
		[0.6, [0.341, 0.722, 0.816]],
		[0.667, [0.26, 0.651, 0.8]],
		[0.733, [0.187, 0.569, 0.756]],
		[0.8, [0.114, 0.493, 0.717]],
		[0.867, [0.041, 0.417, 0.679]],
		[0.933, [0.031, 0.335, 0.596]],
		[1.0, [0.031, 0.251, 0.506]],
	],
	Greens: [
		[0.0, [0.969, 0.988, 0.961]],
		[0.067, [0.931, 0.974, 0.917]],
		[0.133, [0.89, 0.958, 0.87]],
		[0.2, [0.827, 0.933, 0.803]],
		[0.267, [0.761, 0.905, 0.734]],
		[0.333, [0.681, 0.872, 0.656]],
		[0.4, [0.596, 0.835, 0.579]],
		[0.467, [0.502, 0.791, 0.501]],
		[0.533, [0.402, 0.742, 0.437]],
		[0.6, [0.295, 0.69, 0.384]],
		[0.667, [0.216, 0.629, 0.333]],
		[0.733, [0.153, 0.562, 0.283]],
		[0.8, [0.082, 0.498, 0.231]],
		[0.867, [0.009, 0.435, 0.179]],
		[0.933, [0.0, 0.352, 0.141]],
		[1.0, [0.0, 0.267, 0.106]],
	],
	Greys: [
		[0.0, [1.0, 1.0, 1.0]],
		[0.067, [0.969, 0.969, 0.969]],
		[0.133, [0.935, 0.935, 0.935]],
		[0.2, [0.887, 0.887, 0.887]],
		[0.267, [0.836, 0.836, 0.836]],
		[0.333, [0.778, 0.778, 0.778]],
		[0.4, [0.711, 0.711, 0.711]],
		[0.467, [0.629, 0.629, 0.629]],
		[0.533, [0.552, 0.552, 0.552]],
		[0.6, [0.478, 0.478, 0.478]],
		[0.667, [0.408, 0.408, 0.408]],
		[0.733, [0.339, 0.339, 0.339]],
		[0.8, [0.251, 0.251, 0.251]],
		[0.867, [0.157, 0.157, 0.157]],
		[0.933, [0.077, 0.077, 0.077]],
		[1.0, [0.0, 0.0, 0.0]],
	],
	OrRd: [
		[0.0, [1.0, 0.969, 0.925]],
		[0.067, [0.998, 0.937, 0.85]],
		[0.133, [0.996, 0.905, 0.773]],
		[0.2, [0.994, 0.863, 0.685]],
		[0.267, [0.992, 0.818, 0.606]],
		[0.333, [0.992, 0.766, 0.552]],
		[0.4, [0.991, 0.697, 0.484]],
		[0.467, [0.989, 0.601, 0.394]],
		[0.533, [0.975, 0.511, 0.331]],
		[0.6, [0.947, 0.427, 0.296]],
		[0.667, [0.906, 0.327, 0.229]],
		[0.733, [0.856, 0.216, 0.143]],
		[0.8, [0.787, 0.113, 0.073]],
		[0.867, [0.711, 0.013, 0.008]],
		[0.933, [0.607, 0.0, 0.0]],
		[1.0, [0.498, 0.0, 0.0]],
	],
	Oranges: [
		[0.0, [1.0, 0.961, 0.922]],
		[0.067, [0.998, 0.929, 0.861]],
		[0.133, [0.996, 0.896, 0.796]],
		[0.2, [0.994, 0.85, 0.704]],
		[0.267, [0.992, 0.798, 0.607]],
		[0.333, [0.992, 0.727, 0.492]],
		[0.4, [0.992, 0.656, 0.383]],
		[0.467, [0.992, 0.587, 0.284]],
		[0.533, [0.98, 0.515, 0.192]],
		[0.6, [0.955, 0.44, 0.107]],
		[0.667, [0.914, 0.369, 0.051]],
		[0.733, [0.864, 0.3, 0.013]],
		[0.8, [0.771, 0.254, 0.007]],
		[0.867, [0.664, 0.216, 0.011]],
		[0.933, [0.58, 0.184, 0.014]],
		[1.0, [0.498, 0.153, 0.016]],
	],
	PRGn: [
		[0.0, [0.251, 0.0, 0.294]],
		[0.067, [0.392, 0.11, 0.441]],
		[0.133, [0.508, 0.256, 0.566]],
		[0.2, [0.6, 0.439, 0.671]],
		[0.267, [0.707, 0.578, 0.765]],
		[0.333, [0.809, 0.708, 0.844]],
		[0.4, [0.906, 0.831, 0.91]],
		[0.467, [0.948, 0.923, 0.949]],
		[0.533, [0.929, 0.959, 0.922]],
		[0.6, [0.851, 0.941, 0.827]],
		[0.667, [0.718, 0.886, 0.694]],
		[0.733, [0.552, 0.8, 0.545]],
		[0.8, [0.353, 0.682, 0.38]],
		[0.867, [0.188, 0.541, 0.271]],
		[0.933, [0.071, 0.403, 0.179]],
		[1.0, [0.0, 0.267, 0.106]],
	],
	PiYG: [
		[0.0, [0.557, 0.004, 0.322]],
		[0.067, [0.701, 0.072, 0.434]],
		[0.133, [0.805, 0.226, 0.554]],
		[0.2, [0.871, 0.467, 0.682]],
		[0.267, [0.92, 0.631, 0.797]],
		[0.333, [0.961, 0.769, 0.882]],
		[0.4, [0.992, 0.878, 0.937]],
		[0.467, [0.976, 0.939, 0.958]],
		[0.533, [0.946, 0.966, 0.918]],
		[0.6, [0.902, 0.961, 0.816]],
		[0.667, [0.782, 0.908, 0.622]],
		[0.733, [0.647, 0.834, 0.435]],
		[0.8, [0.498, 0.737, 0.255]],
		[0.867, [0.367, 0.627, 0.171]],
		[0.933, [0.252, 0.512, 0.119]],
		[1.0, [0.153, 0.392, 0.098]],
	],
	PuBu: [
		[0.0, [1.0, 0.969, 0.984]],
		[0.067, [0.96, 0.935, 0.965]],
		[0.133, [0.918, 0.9, 0.946]],
		[0.2, [0.86, 0.854, 0.921]],
		[0.267, [0.794, 0.809, 0.896]],
		[0.333, [0.706, 0.767, 0.873]],
		[0.4, [0.612, 0.725, 0.849]],
		[0.467, [0.507, 0.684, 0.824]],
		[0.533, [0.39, 0.637, 0.796]],
		[0.6, [0.26, 0.584, 0.765]],
		[0.667, [0.148, 0.523, 0.732]],
		[0.733, [0.045, 0.456, 0.699]],
		[0.8, [0.018, 0.405, 0.635]],
		[0.867, [0.016, 0.359, 0.562]],
		[0.933, [0.012, 0.291, 0.456]],
		[1.0, [0.008, 0.22, 0.345]],
	],
	PuBuGn: [
		[0.0, [1.0, 0.969, 0.984]],
		[0.067, [0.96, 0.925, 0.961]],
		[0.133, [0.918, 0.882, 0.939]],
		[0.2, [0.86, 0.846, 0.918]],
		[0.267, [0.794, 0.809, 0.896]],
		[0.333, [0.706, 0.767, 0.873]],
		[0.4, [0.602, 0.725, 0.849]],
		[0.467, [0.47, 0.684, 0.824]],
		[0.533, [0.353, 0.637, 0.796]],
		[0.6, [0.25, 0.584, 0.765]],
		[0.667, [0.144, 0.545, 0.682]],
		[0.733, [0.035, 0.514, 0.569]],
		[0.8, [0.006, 0.473, 0.464]],
		[0.867, [0.004, 0.429, 0.362]],
		[0.933, [0.004, 0.354, 0.285]],
		[1.0, [0.004, 0.275, 0.212]],
	],
	PuOr: [
		[0.0, [0.498, 0.231, 0.031]],
		[0.067, [0.634, 0.307, 0.026]],
		[0.133, [0.761, 0.4, 0.042]],
		[0.2, [0.878, 0.51, 0.078]],
		[0.267, [0.954, 0.651, 0.285]],
		[0.333, [0.993, 0.774, 0.497]],
		[0.4, [0.996, 0.878, 0.714]],
		[0.467, [0.978, 0.939, 0.884]],
		[0.533, [0.928, 0.931, 0.953]],
		[0.6, [0.847, 0.855, 0.922]],
		[0.667, [0.748, 0.732, 0.856]],
		[0.733, [0.633, 0.597, 0.774]],
		[0.8, [0.502, 0.451, 0.675]],
		[0.867, [0.387, 0.252, 0.58]],
		[0.933, [0.278, 0.102, 0.454]],
		[1.0, [0.176, 0.0, 0.294]],
	],
	PuRd: [
		[0.0, [0.969, 0.957, 0.976]],
		[0.067, [0.935, 0.917, 0.956]],
		[0.133, [0.901, 0.872, 0.932]],
		[0.2, [0.861, 0.788, 0.888]],
		[0.267, [0.826, 0.706, 0.845]],
		[0.333, [0.803, 0.629, 0.805]],
		[0.4, [0.805, 0.544, 0.762]],
		[0.467, [0.852, 0.445, 0.714]],
		[0.533, [0.883, 0.333, 0.65]],
		[0.6, [0.9, 0.208, 0.571]],
		[0.667, [0.873, 0.131, 0.473]],
		[0.733, [0.821, 0.083, 0.364]],
		[0.8, [0.723, 0.042, 0.307]],
		[0.867, [0.61, 0.005, 0.268]],
		[0.933, [0.506, 0.0, 0.197]],
		[1.0, [0.404, 0.0, 0.122]],
	],
	Purples: [
		[0.0, [0.988, 0.984, 0.992]],
		[0.067, [0.961, 0.955, 0.975]],
		[0.133, [0.932, 0.924, 0.958]],
		[0.2, [0.888, 0.885, 0.937]],
		[0.267, [0.839, 0.84, 0.914]],
		[0.333, [0.776, 0.779, 0.882]],
		[0.4, [0.714, 0.714, 0.847]],
		[0.467, [0.651, 0.641, 0.805]],
		[0.533, [0.588, 0.574, 0.77]],
		[0.6, [0.525, 0.513, 0.74]],
		[0.667, [0.473, 0.433, 0.699]],
		[0.733, [0.427, 0.341, 0.651]],
		[0.8, [0.381, 0.252, 0.608]],
		[0.867, [0.335, 0.164, 0.566]],
		[0.933, [0.291, 0.082, 0.528]],
		[1.0, [0.247, 0.0, 0.49]],
	],
	RdBu: [
		[0.0, [0.404, 0.0, 0.122]],
		[0.067, [0.6, 0.063, 0.153]],
		[0.133, [0.745, 0.188, 0.213]],
		[0.2, [0.839, 0.376, 0.302]],
		[0.267, [0.918, 0.557, 0.441]],
		[0.333, [0.969, 0.718, 0.6]],
		[0.4, [0.992, 0.859, 0.78]],
		[0.467, [0.976, 0.932, 0.906]],
		[0.533, [0.919, 0.945, 0.959]],
		[0.6, [0.82, 0.898, 0.941]],
		[0.667, [0.655, 0.814, 0.894]],
		[0.733, [0.469, 0.707, 0.835]],
		[0.8, [0.263, 0.576, 0.765]],
		[0.867, [0.174, 0.459, 0.705]],
		[0.933, [0.093, 0.329, 0.576]],
		[1.0, [0.02, 0.188, 0.38]],
	],
	RdGy: [
		[0.0, [0.404, 0.0, 0.122]],
		[0.067, [0.6, 0.063, 0.153]],
		[0.133, [0.745, 0.188, 0.213]],
		[0.2, [0.839, 0.376, 0.302]],
		[0.267, [0.918, 0.557, 0.441]],
		[0.333, [0.969, 0.718, 0.6]],
		[0.4, [0.992, 0.859, 0.78]],
		[0.467, [0.997, 0.953, 0.927]],
		[0.533, [0.959, 0.959, 0.959]],
		[0.6, [0.878, 0.878, 0.878]],
		[0.667, [0.779, 0.779, 0.779]],
		[0.733, [0.663, 0.663, 0.663]],
		[0.8, [0.529, 0.529, 0.529]],
		[0.867, [0.378, 0.378, 0.378]],
		[0.933, [0.235, 0.235, 0.235]],
		[1.0, [0.102, 0.102, 0.102]],
	],
	RdPu: [
		[0.0, [1.0, 0.969, 0.953]],
		[0.067, [0.996, 0.921, 0.907]],
		[0.133, [0.992, 0.871, 0.859]],
		[0.2, [0.99, 0.815, 0.798]],
		[0.267, [0.987, 0.753, 0.747]],
		[0.333, [0.983, 0.673, 0.724]],
		[0.4, [0.978, 0.58, 0.694]],
		[0.467, [0.972, 0.465, 0.652]],
		[0.533, [0.941, 0.353, 0.621]],
		[0.6, [0.887, 0.245, 0.6]],
		[0.667, [0.805, 0.137, 0.559]],
		[0.733, [0.707, 0.031, 0.507]],
		[0.8, [0.601, 0.004, 0.483]],
		[0.867, [0.492, 0.004, 0.468]],
		[0.933, [0.389, 0.002, 0.443]],
		[1.0, [0.286, 0.0, 0.416]],
	],
	RdYlBu: [
		[0.0, [0.647, 0.0, 0.149]],
		[0.067, [0.778, 0.125, 0.152]],
		[0.133, [0.881, 0.268, 0.19]],
		[0.2, [0.957, 0.427, 0.263]],
		[0.267, [0.98, 0.597, 0.341]],
		[0.333, [0.993, 0.748, 0.442]],
		[0.4, [0.996, 0.878, 0.565]],
		[0.467, [0.999, 0.959, 0.688]],
		[0.533, [0.959, 0.984, 0.824]],
		[0.6, [0.878, 0.953, 0.973]],
		[0.667, [0.74, 0.885, 0.933]],
		[0.733, [0.599, 0.793, 0.882]],
		[0.8, [0.455, 0.678, 0.82]],
		[0.867, [0.332, 0.532, 0.744]],
		[0.933, [0.244, 0.376, 0.665]],
		[1.0, [0.192, 0.212, 0.584]],
	],
	RdYlGn: [
		[0.0, [0.647, 0.0, 0.149]],
		[0.067, [0.778, 0.125, 0.152]],
		[0.133, [0.881, 0.268, 0.19]],
		[0.2, [0.957, 0.427, 0.263]],
		[0.267, [0.98, 0.597, 0.341]],
		[0.333, [0.993, 0.748, 0.435]],
		[0.4, [0.996, 0.878, 0.545]],
		[0.467, [0.999, 0.959, 0.681]],
		[0.533, [0.95, 0.979, 0.681]],
		[0.6, [0.851, 0.937, 0.545]],
		[0.667, [0.718, 0.88, 0.459]],
		[0.733, [0.567, 0.814, 0.407]],
		[0.8, [0.4, 0.741, 0.388]],
		[0.867, [0.201, 0.644, 0.339]],
		[0.933, [0.068, 0.533, 0.281]],
		[1.0, [0.0, 0.408, 0.216]],
	],
	Reds: [
		[0.0, [1.0, 0.961, 0.941]],
		[0.067, [0.998, 0.917, 0.878]],
		[0.133, [0.996, 0.869, 0.811]],
		[0.2, [0.991, 0.791, 0.708]],
		[0.267, [0.988, 0.712, 0.607]],
		[0.333, [0.988, 0.626, 0.508]],
		[0.4, [0.987, 0.541, 0.416]],
		[0.467, [0.985, 0.458, 0.332]],
		[0.533, [0.972, 0.367, 0.259]],
		[0.6, [0.947, 0.268, 0.196]],
		[0.667, [0.89, 0.186, 0.153]],
		[0.733, [0.815, 0.112, 0.122]],
		[0.8, [0.736, 0.08, 0.101]],
		[0.867, [0.657, 0.061, 0.084]],
		[0.933, [0.534, 0.031, 0.068]],
		[1.0, [0.404, 0.0, 0.051]],
	],
	Spectral: [
		[0.0, [0.62, 0.004, 0.259]],
		[0.067, [0.763, 0.163, 0.293]],
		[0.133, [0.876, 0.305, 0.294]],
		[0.2, [0.957, 0.427, 0.263]],
		[0.267, [0.98, 0.597, 0.341]],
		[0.333, [0.993, 0.748, 0.435]],
		[0.4, [0.996, 0.878, 0.545]],
		[0.467, [0.999, 0.959, 0.681]],
		[0.533, [0.967, 0.987, 0.698]],
		[0.6, [0.902, 0.961, 0.596]],
		[0.667, [0.748, 0.898, 0.627]],
		[0.733, [0.58, 0.831, 0.644]],
		[0.8, [0.4, 0.761, 0.647]],
		[0.867, [0.264, 0.609, 0.71]],
		[0.933, [0.254, 0.459, 0.706]],
		[1.0, [0.369, 0.31, 0.635]],
	],
	Wistia: [
		[0.0, [0.894, 1.0, 0.478]],
		[0.067, [0.922, 0.976, 0.378]],
		[0.133, [0.951, 0.952, 0.278]],
		[0.2, [0.979, 0.928, 0.177]],
		[0.267, [1.0, 0.899, 0.095]],
		[0.333, [1.0, 0.854, 0.068]],
		[0.4, [1.0, 0.809, 0.041]],
		[0.467, [1.0, 0.764, 0.014]],
		[0.533, [1.0, 0.726, 0.0]],
		[0.6, [1.0, 0.696, 0.0]],
		[0.667, [1.0, 0.665, 0.0]],
		[0.733, [1.0, 0.635, 0.0]],
		[0.8, [0.998, 0.602, 0.0]],
		[0.867, [0.995, 0.567, 0.0]],
		[0.933, [0.991, 0.533, 0.0]],
		[1.0, [0.988, 0.498, 0.0]],
	],
	YlGn: [
		[0.0, [1.0, 1.0, 0.898]],
		[0.067, [0.983, 0.994, 0.806]],
		[0.133, [0.961, 0.985, 0.72]],
		[0.2, [0.898, 0.96, 0.674]],
		[0.267, [0.828, 0.931, 0.628]],
		[0.333, [0.736, 0.892, 0.584]],
		[0.4, [0.637, 0.849, 0.54]],
		[0.467, [0.526, 0.801, 0.496]],
		[0.533, [0.413, 0.748, 0.445]],
		[0.6, [0.298, 0.692, 0.387]],
		[0.667, [0.216, 0.62, 0.331]],
		[0.733, [0.153, 0.538, 0.276]],
		[0.8, [0.082, 0.474, 0.244]],
		[0.867, [0.009, 0.415, 0.219]],
		[0.933, [0.0, 0.344, 0.19]],
		[1.0, [0.0, 0.271, 0.161]],
	],
	YlGnBu: [
		[0.0, [1.0, 1.0, 0.851]],
		[0.067, [0.962, 0.985, 0.767]],
		[0.133, [0.919, 0.969, 0.695]],
		[0.2, [0.84, 0.937, 0.701]],
		[0.267, [0.743, 0.899, 0.71]],
		[0.333, [0.592, 0.841, 0.724]],
		[0.4, [0.449, 0.786, 0.74]],
		[0.467, [0.32, 0.738, 0.759]],
		[0.533, [0.217, 0.675, 0.764]],
		[0.6, [0.142, 0.598, 0.756]],
		[0.667, [0.12, 0.502, 0.722]],
		[0.733, [0.131, 0.395, 0.671]],
		[0.8, [0.138, 0.303, 0.627]],
		[0.867, [0.144, 0.215, 0.586]],
		[0.933, [0.092, 0.162, 0.471]],
		[1.0, [0.031, 0.114, 0.345]],
	],
	YlOrBr: [
		[0.0, [1.0, 1.0, 0.898]],
		[0.067, [1.0, 0.983, 0.812]],
		[0.133, [1.0, 0.963, 0.726]],
		[0.2, [0.998, 0.922, 0.636]],
		[0.267, [0.996, 0.874, 0.534]],
		[0.333, [0.996, 0.809, 0.396]],
		[0.4, [0.996, 0.735, 0.28]],
		[0.467, [0.996, 0.645, 0.201]],
		[0.533, [0.977, 0.557, 0.139]],
		[0.6, [0.94, 0.471, 0.095]],
		[0.667, [0.884, 0.392, 0.055]],
		[0.733, [0.817, 0.317, 0.017]],
		[0.8, [0.72, 0.26, 0.011]],
		[0.867, [0.613, 0.21, 0.015]],
		[0.933, [0.507, 0.176, 0.019]],
		[1.0, [0.4, 0.145, 0.024]],
	],
	YlOrRd: [
		[0.0, [1.0, 1.0, 0.8]],
		[0.067, [1.0, 0.962, 0.708]],
		[0.133, [1.0, 0.924, 0.616]],
		[0.2, [0.998, 0.882, 0.529]],
		[0.267, [0.996, 0.831, 0.441]],
		[0.333, [0.996, 0.749, 0.353]],
		[0.4, [0.995, 0.669, 0.285]],
		[0.467, [0.993, 0.592, 0.252]],
		[0.533, [0.991, 0.487, 0.216]],
		[0.6, [0.989, 0.355, 0.179]],
		[0.667, [0.956, 0.238, 0.146]],
		[0.733, [0.903, 0.129, 0.117]],
		[0.8, [0.831, 0.061, 0.125]],
		[0.867, [0.751, 0.007, 0.146]],
		[0.933, [0.63, 0.0, 0.149]],
		[1.0, [0.502, 0.0, 0.149]],
	],
	afmhot: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.133, 0.0, 0.0]],
		[0.133, [0.267, 0.0, 0.0]],
		[0.2, [0.4, 0.0, 0.0]],
		[0.267, [0.533, 0.033, 0.0]],
		[0.333, [0.667, 0.167, 0.0]],
		[0.4, [0.8, 0.3, 0.0]],
		[0.467, [0.933, 0.433, 0.0]],
		[0.533, [1.0, 0.567, 0.067]],
		[0.6, [1.0, 0.7, 0.2]],
		[0.667, [1.0, 0.833, 0.333]],
		[0.733, [1.0, 0.967, 0.467]],
		[0.8, [1.0, 1.0, 0.6]],
		[0.867, [1.0, 1.0, 0.733]],
		[0.933, [1.0, 1.0, 0.867]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	autumn: [
		[0.0, [1.0, 0.0, 0.0]],
		[0.067, [1.0, 0.067, 0.0]],
		[0.133, [1.0, 0.133, 0.0]],
		[0.2, [1.0, 0.2, 0.0]],
		[0.267, [1.0, 0.267, 0.0]],
		[0.333, [1.0, 0.333, 0.0]],
		[0.4, [1.0, 0.4, 0.0]],
		[0.467, [1.0, 0.467, 0.0]],
		[0.533, [1.0, 0.533, 0.0]],
		[0.6, [1.0, 0.6, 0.0]],
		[0.667, [1.0, 0.667, 0.0]],
		[0.733, [1.0, 0.733, 0.0]],
		[0.8, [1.0, 0.8, 0.0]],
		[0.867, [1.0, 0.867, 0.0]],
		[0.933, [1.0, 0.933, 0.0]],
		[1.0, [1.0, 1.0, 0.0]],
	],
	binary: [
		[0.0, [1.0, 1.0, 1.0]],
		[0.067, [0.933, 0.933, 0.933]],
		[0.133, [0.867, 0.867, 0.867]],
		[0.2, [0.8, 0.8, 0.8]],
		[0.267, [0.733, 0.733, 0.733]],
		[0.333, [0.667, 0.667, 0.667]],
		[0.4, [0.6, 0.6, 0.6]],
		[0.467, [0.533, 0.533, 0.533]],
		[0.533, [0.467, 0.467, 0.467]],
		[0.6, [0.4, 0.4, 0.4]],
		[0.667, [0.333, 0.333, 0.333]],
		[0.733, [0.267, 0.267, 0.267]],
		[0.8, [0.2, 0.2, 0.2]],
		[0.867, [0.133, 0.133, 0.133]],
		[0.933, [0.067, 0.067, 0.067]],
		[1.0, [0.0, 0.0, 0.0]],
	],
	bone: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.058, 0.058, 0.081]],
		[0.133, [0.117, 0.117, 0.162]],
		[0.2, [0.175, 0.175, 0.243]],
		[0.267, [0.233, 0.233, 0.325]],
		[0.333, [0.292, 0.292, 0.406]],
		[0.4, [0.35, 0.361, 0.475]],
		[0.467, [0.408, 0.442, 0.533]],
		[0.533, [0.467, 0.522, 0.592]],
		[0.6, [0.525, 0.602, 0.65]],
		[0.667, [0.583, 0.682, 0.708]],
		[0.733, [0.642, 0.762, 0.767]],
		[0.8, [0.727, 0.825, 0.825]],
		[0.867, [0.818, 0.883, 0.883]],
		[0.933, [0.909, 0.942, 0.942]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	brg: [
		[0.0, [0.0, 0.0, 1.0]],
		[0.067, [0.133, 0.0, 0.867]],
		[0.133, [0.267, 0.0, 0.733]],
		[0.2, [0.4, 0.0, 0.6]],
		[0.267, [0.533, 0.0, 0.467]],
		[0.333, [0.667, 0.0, 0.333]],
		[0.4, [0.8, 0.0, 0.2]],
		[0.467, [0.933, 0.0, 0.067]],
		[0.533, [0.933, 0.067, 0.0]],
		[0.6, [0.8, 0.2, 0.0]],
		[0.667, [0.667, 0.333, 0.0]],
		[0.733, [0.533, 0.467, 0.0]],
		[0.8, [0.4, 0.6, 0.0]],
		[0.867, [0.267, 0.733, 0.0]],
		[0.933, [0.133, 0.867, 0.0]],
		[1.0, [0.0, 1.0, 0.0]],
	],
	bwr: [
		[0.0, [0.0, 0.0, 1.0]],
		[0.067, [0.133, 0.133, 1.0]],
		[0.133, [0.267, 0.267, 1.0]],
		[0.2, [0.4, 0.4, 1.0]],
		[0.267, [0.533, 0.533, 1.0]],
		[0.333, [0.667, 0.667, 1.0]],
		[0.4, [0.8, 0.8, 1.0]],
		[0.467, [0.933, 0.933, 1.0]],
		[0.533, [1.0, 0.933, 0.933]],
		[0.6, [1.0, 0.8, 0.8]],
		[0.667, [1.0, 0.667, 0.667]],
		[0.733, [1.0, 0.533, 0.533]],
		[0.8, [1.0, 0.4, 0.4]],
		[0.867, [1.0, 0.267, 0.267]],
		[0.933, [1.0, 0.133, 0.133]],
		[1.0, [1.0, 0.0, 0.0]],
	],
	cool: [
		[0.0, [0.0, 1.0, 1.0]],
		[0.067, [0.067, 0.933, 1.0]],
		[0.133, [0.133, 0.867, 1.0]],
		[0.2, [0.2, 0.8, 1.0]],
		[0.267, [0.267, 0.733, 1.0]],
		[0.333, [0.333, 0.667, 1.0]],
		[0.4, [0.4, 0.6, 1.0]],
		[0.467, [0.467, 0.533, 1.0]],
		[0.533, [0.533, 0.467, 1.0]],
		[0.6, [0.6, 0.4, 1.0]],
		[0.667, [0.667, 0.333, 1.0]],
		[0.733, [0.733, 0.267, 1.0]],
		[0.8, [0.8, 0.2, 1.0]],
		[0.867, [0.867, 0.133, 1.0]],
		[0.933, [0.933, 0.067, 1.0]],
		[1.0, [1.0, 0.0, 1.0]],
	],
	coolwarm: [
		[0.0, [0.23, 0.299, 0.754]],
		[0.067, [0.309, 0.413, 0.85]],
		[0.133, [0.394, 0.522, 0.925]],
		[0.2, [0.484, 0.622, 0.975]],
		[0.267, [0.576, 0.709, 0.998]],
		[0.333, [0.667, 0.779, 0.993]],
		[0.4, [0.754, 0.83, 0.961]],
		[0.467, [0.831, 0.86, 0.903]],
		[0.533, [0.9, 0.848, 0.818]],
		[0.6, [0.947, 0.795, 0.717]],
		[0.667, [0.968, 0.721, 0.612]],
		[0.733, [0.963, 0.628, 0.508]],
		[0.8, [0.932, 0.519, 0.406]],
		[0.867, [0.877, 0.395, 0.312]],
		[0.933, [0.801, 0.251, 0.226]],
		[1.0, [0.706, 0.016, 0.15]],
	],
	copper: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.082, 0.052, 0.033]],
		[0.133, [0.165, 0.104, 0.066]],
		[0.2, [0.247, 0.156, 0.1]],
		[0.267, [0.329, 0.208, 0.133]],
		[0.333, [0.412, 0.26, 0.166]],
		[0.4, [0.494, 0.312, 0.199]],
		[0.467, [0.576, 0.365, 0.232]],
		[0.533, [0.659, 0.417, 0.265]],
		[0.6, [0.741, 0.469, 0.298]],
		[0.667, [0.824, 0.521, 0.332]],
		[0.733, [0.906, 0.573, 0.365]],
		[0.8, [0.988, 0.625, 0.398]],
		[0.867, [1.0, 0.677, 0.431]],
		[0.933, [1.0, 0.729, 0.464]],
		[1.0, [1.0, 0.781, 0.497]],
	],
	cubehelix: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.085, 0.047, 0.123]],
		[0.133, [0.104, 0.128, 0.245]],
		[0.2, [0.086, 0.238, 0.306]],
		[0.267, [0.092, 0.352, 0.287]],
		[0.333, [0.17, 0.437, 0.224]],
		[0.4, [0.329, 0.476, 0.184]],
		[0.467, [0.531, 0.479, 0.227]],
		[0.533, [0.711, 0.474, 0.369]],
		[0.6, [0.815, 0.495, 0.575]],
		[0.667, [0.83, 0.563, 0.776]],
		[0.733, [0.791, 0.671, 0.91]],
		[0.8, [0.759, 0.792, 0.954]],
		[0.867, [0.784, 0.894, 0.943]],
		[0.933, [0.878, 0.96, 0.94]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	flag: [
		[0.0, [1.0, 0.0, 0.0]],
		[0.067, [1.0, 0.309, 0.16]],
		[0.133, [1.0, 0.588, 0.383]],
		[0.2, [1.0, 0.809, 0.617]],
		[0.267, [1.0, 0.951, 0.84]],
		[0.333, [1.0, 1.0, 1.0]],
		[0.4, [0.84, 0.951, 1.0]],
		[0.467, [0.617, 0.809, 1.0]],
		[0.533, [0.383, 0.588, 1.0]],
		[0.6, [0.16, 0.309, 1.0]],
		[0.667, [0.0, 0.0, 1.0]],
		[0.733, [0.0, 0.0, 0.84]],
		[0.8, [0.0, 0.0, 0.617]],
		[0.867, [0.0, 0.0, 0.383]],
		[0.933, [0.0, 0.0, 0.16]],
		[1.0, [0.0, 0.0, 0.0]],
	],
	gist_earth: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.044, 0.09, 0.461]],
		[0.133, [0.089, 0.238, 0.473]],
		[0.2, [0.133, 0.37, 0.485]],
		[0.267, [0.178, 0.477, 0.498]],
		[0.333, [0.212, 0.531, 0.438]],
		[0.4, [0.244, 0.57, 0.356]],
		[0.467, [0.289, 0.608, 0.275]],
		[0.533, [0.437, 0.645, 0.314]],
		[0.6, [0.556, 0.674, 0.338]],
		[0.667, [0.666, 0.703, 0.359]],
		[0.733, [0.732, 0.687, 0.38]],
		[0.8, [0.768, 0.645, 0.435]],
		[0.867, [0.843, 0.699, 0.598]],
		[0.933, [0.918, 0.812, 0.783]],
		[1.0, [0.992, 0.984, 0.984]],
	],
	gist_gray: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.067, 0.067, 0.067]],
		[0.133, [0.133, 0.133, 0.133]],
		[0.2, [0.2, 0.2, 0.2]],
		[0.267, [0.267, 0.267, 0.267]],
		[0.333, [0.333, 0.333, 0.333]],
		[0.4, [0.4, 0.4, 0.4]],
		[0.467, [0.467, 0.467, 0.467]],
		[0.533, [0.533, 0.533, 0.533]],
		[0.6, [0.6, 0.6, 0.6]],
		[0.667, [0.667, 0.667, 0.667]],
		[0.733, [0.733, 0.733, 0.733]],
		[0.8, [0.8, 0.8, 0.8]],
		[0.867, [0.867, 0.867, 0.867]],
		[0.933, [0.933, 0.933, 0.933]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	gist_heat: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.1, 0.0, 0.0]],
		[0.133, [0.2, 0.0, 0.0]],
		[0.2, [0.3, 0.0, 0.0]],
		[0.267, [0.4, 0.0, 0.0]],
		[0.333, [0.5, 0.0, 0.0]],
		[0.4, [0.6, 0.0, 0.0]],
		[0.467, [0.7, 0.0, 0.0]],
		[0.533, [0.8, 0.067, 0.0]],
		[0.6, [0.9, 0.2, 0.0]],
		[0.667, [1.0, 0.333, 0.0]],
		[0.733, [1.0, 0.467, 0.0]],
		[0.8, [1.0, 0.6, 0.2]],
		[0.867, [1.0, 0.733, 0.467]],
		[0.933, [1.0, 0.867, 0.733]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	gist_ncar: [
		[0.0, [0.0, 0.0, 0.502]],
		[0.067, [0.0, 0.266, 0.283]],
		[0.133, [0.0, 0.387, 1.0]],
		[0.2, [0.0, 0.931, 1.0]],
		[0.267, [0.0, 0.98, 0.573]],
		[0.333, [0.15, 0.941, 0.0]],
		[0.4, [0.454, 0.91, 0.0]],
		[0.467, [0.697, 1.0, 0.203]],
		[0.533, [1.0, 0.971, 0.0]],
		[0.6, [1.0, 0.807, 0.021]],
		[0.667, [1.0, 0.44, 0.022]],
		[0.733, [1.0, 0.056, 0.0]],
		[0.8, [0.946, 0.027, 1.0]],
		[0.867, [0.738, 0.315, 0.972]],
		[0.933, [0.949, 0.664, 0.956]],
		[1.0, [0.996, 0.973, 0.996]],
	],
	gist_rainbow: [
		[0.0, [1.0, 0.0, 0.16]],
		[0.067, [1.0, 0.198, 0.0]],
		[0.133, [1.0, 0.559, 0.0]],
		[0.2, [1.0, 0.919, 0.0]],
		[0.267, [0.721, 1.0, 0.0]],
		[0.333, [0.36, 1.0, 0.0]],
		[0.4, [0.0, 1.0, 0.0]],
		[0.467, [0.0, 1.0, 0.358]],
		[0.533, [0.0, 1.0, 0.717]],
		[0.6, [0.0, 0.924, 1.0]],
		[0.667, [0.0, 0.562, 1.0]],
		[0.733, [0.0, 0.199, 1.0]],
		[0.8, [0.163, 0.0, 1.0]],
		[0.867, [0.525, 0.0, 1.0]],
		[0.933, [0.888, 0.0, 1.0]],
		[1.0, [1.0, 0.0, 0.75]],
	],
	gist_stern: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.94, 0.067, 0.133]],
		[0.133, [0.608, 0.133, 0.267]],
		[0.2, [0.276, 0.2, 0.4]],
		[0.267, [0.267, 0.267, 0.533]],
		[0.333, [0.333, 0.333, 0.667]],
		[0.4, [0.4, 0.4, 0.8]],
		[0.467, [0.467, 0.467, 0.933]],
		[0.533, [0.533, 0.533, 0.858]],
		[0.6, [0.6, 0.6, 0.574]],
		[0.667, [0.667, 0.667, 0.291]],
		[0.733, [0.733, 0.733, 0.007]],
		[0.8, [0.8, 0.8, 0.245]],
		[0.867, [0.867, 0.867, 0.497]],
		[0.933, [0.933, 0.933, 0.748]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	gist_yarg: [
		[0.0, [1.0, 1.0, 1.0]],
		[0.067, [0.933, 0.933, 0.933]],
		[0.133, [0.867, 0.867, 0.867]],
		[0.2, [0.8, 0.8, 0.8]],
		[0.267, [0.733, 0.733, 0.733]],
		[0.333, [0.667, 0.667, 0.667]],
		[0.4, [0.6, 0.6, 0.6]],
		[0.467, [0.533, 0.533, 0.533]],
		[0.533, [0.467, 0.467, 0.467]],
		[0.6, [0.4, 0.4, 0.4]],
		[0.667, [0.333, 0.333, 0.333]],
		[0.733, [0.267, 0.267, 0.267]],
		[0.8, [0.2, 0.2, 0.2]],
		[0.867, [0.133, 0.133, 0.133]],
		[0.933, [0.067, 0.067, 0.067]],
		[1.0, [0.0, 0.0, 0.0]],
	],
	gnuplot: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.258, 0.0, 0.407]],
		[0.133, [0.365, 0.002, 0.743]],
		[0.2, [0.447, 0.008, 0.951]],
		[0.267, [0.516, 0.019, 0.995]],
		[0.333, [0.577, 0.037, 0.866]],
		[0.4, [0.632, 0.064, 0.588]],
		[0.467, [0.683, 0.102, 0.208]],
		[0.533, [0.73, 0.152, 0.0]],
		[0.6, [0.775, 0.216, 0.0]],
		[0.667, [0.816, 0.296, 0.0]],
		[0.733, [0.856, 0.394, 0.0]],
		[0.8, [0.894, 0.512, 0.0]],
		[0.867, [0.931, 0.651, 0.0]],
		[0.933, [0.966, 0.813, 0.0]],
		[1.0, [1.0, 1.0, 0.0]],
	],
	gnuplot2: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.0, 0.0, 0.267]],
		[0.133, [0.0, 0.0, 0.533]],
		[0.2, [0.0, 0.0, 0.8]],
		[0.267, [0.052, 0.0, 1.0]],
		[0.333, [0.26, 0.0, 1.0]],
		[0.4, [0.469, 0.0, 1.0]],
		[0.467, [0.677, 0.093, 0.907]],
		[0.533, [0.885, 0.227, 0.773]],
		[0.6, [1.0, 0.36, 0.64]],
		[0.667, [1.0, 0.493, 0.507]],
		[0.733, [1.0, 0.627, 0.373]],
		[0.8, [1.0, 0.76, 0.24]],
		[0.867, [1.0, 0.893, 0.107]],
		[0.933, [1.0, 1.0, 0.167]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	gray: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.067, 0.067, 0.067]],
		[0.133, [0.133, 0.133, 0.133]],
		[0.2, [0.2, 0.2, 0.2]],
		[0.267, [0.267, 0.267, 0.267]],
		[0.333, [0.333, 0.333, 0.333]],
		[0.4, [0.4, 0.4, 0.4]],
		[0.467, [0.467, 0.467, 0.467]],
		[0.533, [0.533, 0.533, 0.533]],
		[0.6, [0.6, 0.6, 0.6]],
		[0.667, [0.667, 0.667, 0.667]],
		[0.733, [0.733, 0.733, 0.733]],
		[0.8, [0.8, 0.8, 0.8]],
		[0.867, [0.867, 0.867, 0.867]],
		[0.933, [0.933, 0.933, 0.933]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	hot: [
		[0.0, [0.042, 0.0, 0.0]],
		[0.067, [0.217, 0.0, 0.0]],
		[0.133, [0.392, 0.0, 0.0]],
		[0.2, [0.567, 0.0, 0.0]],
		[0.267, [0.742, 0.0, 0.0]],
		[0.333, [0.917, 0.0, 0.0]],
		[0.4, [1.0, 0.092, 0.0]],
		[0.467, [1.0, 0.267, 0.0]],
		[0.533, [1.0, 0.442, 0.0]],
		[0.6, [1.0, 0.617, 0.0]],
		[0.667, [1.0, 0.792, 0.0]],
		[0.733, [1.0, 0.967, 0.0]],
		[0.8, [1.0, 1.0, 0.212]],
		[0.867, [1.0, 1.0, 0.475]],
		[0.933, [1.0, 1.0, 0.737]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	hsv: [
		[0.0, [1.0, 0.0, 0.0]],
		[0.067, [1.0, 0.394, 0.0]],
		[0.133, [1.0, 0.788, 0.0]],
		[0.2, [0.819, 1.0, 0.0]],
		[0.267, [0.425, 1.0, 0.0]],
		[0.333, [0.031, 1.0, 0.0]],
		[0.4, [0.0, 1.0, 0.363]],
		[0.467, [0.0, 1.0, 0.756]],
		[0.533, [0.0, 0.85, 1.0]],
		[0.6, [0.0, 0.456, 1.0]],
		[0.667, [0.0, 0.063, 1.0]],
		[0.733, [0.331, 0.0, 1.0]],
		[0.8, [0.725, 0.0, 1.0]],
		[0.867, [1.0, 0.0, 0.881]],
		[0.933, [1.0, 0.0, 0.488]],
		[1.0, [1.0, 0.0, 0.094]],
	],
	jet: [
		[0.0, [0.0, 0.0, 0.5]],
		[0.067, [0.0, 0.0, 0.803]],
		[0.133, [0.0, 0.033, 1.0]],
		[0.2, [0.0, 0.3, 1.0]],
		[0.267, [0.0, 0.567, 1.0]],
		[0.333, [0.0, 0.833, 1.0]],
		[0.4, [0.161, 1.0, 0.806]],
		[0.467, [0.376, 1.0, 0.591]],
		[0.533, [0.591, 1.0, 0.376]],
		[0.6, [0.806, 1.0, 0.161]],
		[0.667, [1.0, 0.901, 0.0]],
		[0.733, [1.0, 0.654, 0.0]],
		[0.8, [1.0, 0.407, 0.0]],
		[0.867, [1.0, 0.16, 0.0]],
		[0.933, [0.803, 0.0, 0.0]],
		[1.0, [0.5, 0.0, 0.0]],
	],
	nipy_spectral: [
		[0.0, [0.0, 0.0, 0.0]],
		[0.067, [0.489, 0.0, 0.556]],
		[0.133, [0.178, 0.0, 0.644]],
		[0.2, [0.0, 0.0, 0.867]],
		[0.267, [0.0, 0.511, 0.867]],
		[0.333, [0.0, 0.644, 0.733]],
		[0.4, [0.0, 0.667, 0.533]],
		[0.467, [0.0, 0.644, 0.0]],
		[0.533, [0.0, 0.822, 0.0]],
		[0.6, [0.0, 1.0, 0.0]],
		[0.667, [0.8, 0.978, 0.0]],
		[0.733, [0.978, 0.844, 0.0]],
		[0.8, [1.0, 0.6, 0.0]],
		[0.867, [0.956, 0.0, 0.0]],
		[0.933, [0.822, 0.0, 0.0]],
		[1.0, [0.8, 0.8, 0.8]],
	],
	ocean: [
		[0.0, [0.0, 0.5, 0.0]],
		[0.067, [0.0, 0.4, 0.067]],
		[0.133, [0.0, 0.3, 0.133]],
		[0.2, [0.0, 0.2, 0.2]],
		[0.267, [0.0, 0.1, 0.267]],
		[0.333, [0.0, 0.0, 0.333]],
		[0.4, [0.0, 0.1, 0.4]],
		[0.467, [0.0, 0.2, 0.467]],
		[0.533, [0.0, 0.3, 0.533]],
		[0.6, [0.0, 0.4, 0.6]],
		[0.667, [0.0, 0.5, 0.667]],
		[0.733, [0.2, 0.6, 0.733]],
		[0.8, [0.4, 0.7, 0.8]],
		[0.867, [0.6, 0.8, 0.867]],
		[0.933, [0.8, 0.9, 0.933]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	pink: [
		[0.0, [0.118, 0.0, 0.0]],
		[0.067, [0.341, 0.211, 0.211]],
		[0.133, [0.468, 0.298, 0.298]],
		[0.2, [0.568, 0.365, 0.365]],
		[0.267, [0.652, 0.422, 0.422]],
		[0.333, [0.726, 0.471, 0.471]],
		[0.4, [0.775, 0.545, 0.516]],
		[0.467, [0.803, 0.632, 0.558]],
		[0.533, [0.83, 0.709, 0.596]],
		[0.6, [0.856, 0.778, 0.632]],
		[0.667, [0.882, 0.842, 0.667]],
		[0.733, [0.907, 0.901, 0.699]],
		[0.8, [0.931, 0.931, 0.777]],
		[0.867, [0.955, 0.955, 0.858]],
		[0.933, [0.978, 0.978, 0.932]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	prism: [
		[0.0, [1.0, 0.0, 0.0]],
		[0.067, [0.0, 0.004, 1.0]],
		[0.133, [0.584, 1.0, 0.0]],
		[0.2, [1.0, 0.166, 0.0]],
		[0.267, [0.275, 0.0, 1.0]],
		[0.333, [0.198, 0.913, 0.0]],
		[0.4, [1.0, 0.584, 0.0]],
		[0.467, [0.678, 0.0, 0.77]],
		[0.533, [0.0, 0.569, 0.489]],
		[0.6, [1.0, 0.923, 0.0]],
		[0.667, [1.0, 0.0, 0.229]],
		[0.733, [0.0, 0.151, 0.941]],
		[0.8, [0.741, 1.0, 0.0]],
		[0.867, [1.0, 0.018, 0.0]],
		[0.933, [0.151, 0.0, 1.0]],
		[1.0, [0.33, 0.998, 0.0]],
	],
	rainbow: [
		[0.0, [0.5, 0.0, 1.0]],
		[0.067, [0.367, 0.208, 0.995]],
		[0.133, [0.233, 0.407, 0.978]],
		[0.2, [0.1, 0.588, 0.951]],
		[0.267, [0.033, 0.743, 0.914]],
		[0.333, [0.167, 0.866, 0.866]],
		[0.4, [0.3, 0.951, 0.809]],
		[0.467, [0.433, 0.995, 0.743]],
		[0.533, [0.567, 0.995, 0.669]],
		[0.6, [0.7, 0.951, 0.588]],
		[0.667, [0.833, 0.866, 0.5]],
		[0.733, [0.967, 0.743, 0.407]],
		[0.8, [1.0, 0.588, 0.309]],
		[0.867, [1.0, 0.407, 0.208]],
		[0.933, [1.0, 0.208, 0.105]],
		[1.0, [1.0, 0.0, 0.0]],
	],
	seismic: [
		[0.0, [0.0, 0.0, 0.3]],
		[0.067, [0.0, 0.0, 0.487]],
		[0.133, [0.0, 0.0, 0.673]],
		[0.2, [0.0, 0.0, 0.86]],
		[0.267, [0.067, 0.067, 1.0]],
		[0.333, [0.333, 0.333, 1.0]],
		[0.4, [0.6, 0.6, 1.0]],
		[0.467, [0.867, 0.867, 1.0]],
		[0.533, [1.0, 0.867, 0.867]],
		[0.6, [1.0, 0.6, 0.6]],
		[0.667, [1.0, 0.333, 0.333]],
		[0.733, [1.0, 0.067, 0.067]],
		[0.8, [0.9, 0.0, 0.0]],
		[0.867, [0.767, 0.0, 0.0]],
		[0.933, [0.633, 0.0, 0.0]],
		[1.0, [0.5, 0.0, 0.0]],
	],
	spring: [
		[0.0, [1.0, 0.0, 1.0]],
		[0.067, [1.0, 0.067, 0.933]],
		[0.133, [1.0, 0.133, 0.867]],
		[0.2, [1.0, 0.2, 0.8]],
		[0.267, [1.0, 0.267, 0.733]],
		[0.333, [1.0, 0.333, 0.667]],
		[0.4, [1.0, 0.4, 0.6]],
		[0.467, [1.0, 0.467, 0.533]],
		[0.533, [1.0, 0.533, 0.467]],
		[0.6, [1.0, 0.6, 0.4]],
		[0.667, [1.0, 0.667, 0.333]],
		[0.733, [1.0, 0.733, 0.267]],
		[0.8, [1.0, 0.8, 0.2]],
		[0.867, [1.0, 0.867, 0.133]],
		[0.933, [1.0, 0.933, 0.067]],
		[1.0, [1.0, 1.0, 0.0]],
	],
	summer: [
		[0.0, [0.0, 0.5, 0.4]],
		[0.067, [0.067, 0.533, 0.4]],
		[0.133, [0.133, 0.567, 0.4]],
		[0.2, [0.2, 0.6, 0.4]],
		[0.267, [0.267, 0.633, 0.4]],
		[0.333, [0.333, 0.667, 0.4]],
		[0.4, [0.4, 0.7, 0.4]],
		[0.467, [0.467, 0.733, 0.4]],
		[0.533, [0.533, 0.767, 0.4]],
		[0.6, [0.6, 0.8, 0.4]],
		[0.667, [0.667, 0.833, 0.4]],
		[0.733, [0.733, 0.867, 0.4]],
		[0.8, [0.8, 0.9, 0.4]],
		[0.867, [0.867, 0.933, 0.4]],
		[0.933, [0.933, 0.967, 0.4]],
		[1.0, [1.0, 1.0, 0.4]],
	],
	terrain: [
		[0.0, [0.2, 0.2, 0.6]],
		[0.067, [0.111, 0.378, 0.778]],
		[0.133, [0.022, 0.556, 0.956]],
		[0.2, [0.0, 0.7, 0.7]],
		[0.267, [0.067, 0.813, 0.413]],
		[0.333, [0.333, 0.867, 0.467]],
		[0.4, [0.6, 0.92, 0.52]],
		[0.467, [0.867, 0.973, 0.573]],
		[0.533, [0.933, 0.915, 0.564]],
		[0.6, [0.8, 0.744, 0.492]],
		[0.667, [0.667, 0.573, 0.42]],
		[0.733, [0.533, 0.403, 0.348]],
		[0.8, [0.6, 0.488, 0.464]],
		[0.867, [0.733, 0.659, 0.643]],
		[0.933, [0.867, 0.829, 0.821]],
		[1.0, [1.0, 1.0, 1.0]],
	],
	winter: [
		[0.0, [0.0, 0.0, 1.0]],
		[0.067, [0.0, 0.067, 0.967]],
		[0.133, [0.0, 0.133, 0.933]],
		[0.2, [0.0, 0.2, 0.9]],
		[0.267, [0.0, 0.267, 0.867]],
		[0.333, [0.0, 0.333, 0.833]],
		[0.4, [0.0, 0.4, 0.8]],
		[0.467, [0.0, 0.467, 0.767]],
		[0.533, [0.0, 0.533, 0.733]],
		[0.6, [0.0, 0.6, 0.7]],
		[0.667, [0.0, 0.667, 0.667]],
		[0.733, [0.0, 0.733, 0.633]],
		[0.8, [0.0, 0.8, 0.6]],
		[0.867, [0.0, 0.867, 0.567]],
		[0.933, [0.0, 0.933, 0.533]],
		[1.0, [0.0, 1.0, 0.5]],
	],
	Accent: [
		[0.0, [0.498, 0.788, 0.498]],
		[0.067, [0.498, 0.788, 0.498]],
		[0.133, [0.745, 0.682, 0.831]],
		[0.2, [0.745, 0.682, 0.831]],
		[0.267, [0.992, 0.753, 0.525]],
		[0.333, [0.992, 0.753, 0.525]],
		[0.4, [1.0, 1.0, 0.6]],
		[0.467, [1.0, 1.0, 0.6]],
		[0.533, [0.22, 0.424, 0.69]],
		[0.6, [0.22, 0.424, 0.69]],
		[0.667, [0.941, 0.008, 0.498]],
		[0.733, [0.941, 0.008, 0.498]],
		[0.8, [0.749, 0.357, 0.09]],
		[0.867, [0.749, 0.357, 0.09]],
		[0.933, [0.4, 0.4, 0.4]],
		[1.0, [0.4, 0.4, 0.4]],
	],
	Dark2: [
		[0.0, [0.106, 0.62, 0.467]],
		[0.067, [0.106, 0.62, 0.467]],
		[0.133, [0.851, 0.373, 0.008]],
		[0.2, [0.851, 0.373, 0.008]],
		[0.267, [0.459, 0.439, 0.702]],
		[0.333, [0.459, 0.439, 0.702]],
		[0.4, [0.906, 0.161, 0.541]],
		[0.467, [0.906, 0.161, 0.541]],
		[0.533, [0.4, 0.651, 0.118]],
		[0.6, [0.4, 0.651, 0.118]],
		[0.667, [0.902, 0.671, 0.008]],
		[0.733, [0.902, 0.671, 0.008]],
		[0.8, [0.651, 0.463, 0.114]],
		[0.867, [0.651, 0.463, 0.114]],
		[0.933, [0.4, 0.4, 0.4]],
		[1.0, [0.4, 0.4, 0.4]],
	],
	Paired: [
		[0.0, [0.651, 0.808, 0.89]],
		[0.067, [0.651, 0.808, 0.89]],
		[0.133, [0.122, 0.471, 0.706]],
		[0.2, [0.698, 0.875, 0.541]],
		[0.267, [0.2, 0.627, 0.173]],
		[0.333, [0.984, 0.604, 0.6]],
		[0.4, [0.984, 0.604, 0.6]],
		[0.467, [0.89, 0.102, 0.11]],
		[0.533, [0.992, 0.749, 0.435]],
		[0.6, [1.0, 0.498, 0.0]],
		[0.667, [0.792, 0.698, 0.839]],
		[0.733, [0.792, 0.698, 0.839]],
		[0.8, [0.416, 0.239, 0.604]],
		[0.867, [1.0, 1.0, 0.6]],
		[0.933, [0.694, 0.349, 0.157]],
		[1.0, [0.694, 0.349, 0.157]],
	],
	Pastel1: [
		[0.0, [0.984, 0.706, 0.682]],
		[0.067, [0.984, 0.706, 0.682]],
		[0.133, [0.702, 0.804, 0.89]],
		[0.2, [0.702, 0.804, 0.89]],
		[0.267, [0.8, 0.922, 0.773]],
		[0.333, [0.871, 0.796, 0.894]],
		[0.4, [0.871, 0.796, 0.894]],
		[0.467, [0.996, 0.851, 0.651]],
		[0.533, [0.996, 0.851, 0.651]],
		[0.6, [1.0, 1.0, 0.8]],
		[0.667, [0.898, 0.847, 0.741]],
		[0.733, [0.898, 0.847, 0.741]],
		[0.8, [0.992, 0.855, 0.925]],
		[0.867, [0.992, 0.855, 0.925]],
		[0.933, [0.949, 0.949, 0.949]],
		[1.0, [0.949, 0.949, 0.949]],
	],
	Pastel2: [
		[0.0, [0.702, 0.886, 0.804]],
		[0.067, [0.702, 0.886, 0.804]],
		[0.133, [0.992, 0.804, 0.675]],
		[0.2, [0.992, 0.804, 0.675]],
		[0.267, [0.796, 0.835, 0.91]],
		[0.333, [0.796, 0.835, 0.91]],
		[0.4, [0.957, 0.792, 0.894]],
		[0.467, [0.957, 0.792, 0.894]],
		[0.533, [0.902, 0.961, 0.788]],
		[0.6, [0.902, 0.961, 0.788]],
		[0.667, [1.0, 0.949, 0.682]],
		[0.733, [1.0, 0.949, 0.682]],
		[0.8, [0.945, 0.886, 0.8]],
		[0.867, [0.945, 0.886, 0.8]],
		[0.933, [0.8, 0.8, 0.8]],
		[1.0, [0.8, 0.8, 0.8]],
	],
	Set1: [
		[0.0, [0.894, 0.102, 0.11]],
		[0.067, [0.894, 0.102, 0.11]],
		[0.133, [0.216, 0.494, 0.722]],
		[0.2, [0.216, 0.494, 0.722]],
		[0.267, [0.302, 0.686, 0.29]],
		[0.333, [0.596, 0.306, 0.639]],
		[0.4, [0.596, 0.306, 0.639]],
		[0.467, [1.0, 0.498, 0.0]],
		[0.533, [1.0, 0.498, 0.0]],
		[0.6, [1.0, 1.0, 0.2]],
		[0.667, [0.651, 0.337, 0.157]],
		[0.733, [0.651, 0.337, 0.157]],
		[0.8, [0.969, 0.506, 0.749]],
		[0.867, [0.969, 0.506, 0.749]],
		[0.933, [0.6, 0.6, 0.6]],
		[1.0, [0.6, 0.6, 0.6]],
	],
	Set2: [
		[0.0, [0.4, 0.761, 0.647]],
		[0.067, [0.4, 0.761, 0.647]],
		[0.133, [0.988, 0.553, 0.384]],
		[0.2, [0.988, 0.553, 0.384]],
		[0.267, [0.553, 0.627, 0.796]],
		[0.333, [0.553, 0.627, 0.796]],
		[0.4, [0.906, 0.541, 0.765]],
		[0.467, [0.906, 0.541, 0.765]],
		[0.533, [0.651, 0.847, 0.329]],
		[0.6, [0.651, 0.847, 0.329]],
		[0.667, [1.0, 0.851, 0.184]],
		[0.733, [1.0, 0.851, 0.184]],
		[0.8, [0.898, 0.769, 0.58]],
		[0.867, [0.898, 0.769, 0.58]],
		[0.933, [0.702, 0.702, 0.702]],
		[1.0, [0.702, 0.702, 0.702]],
	],
	Set3: [
		[0.0, [0.553, 0.827, 0.78]],
		[0.067, [0.553, 0.827, 0.78]],
		[0.133, [1.0, 1.0, 0.702]],
		[0.2, [0.745, 0.729, 0.855]],
		[0.267, [0.984, 0.502, 0.447]],
		[0.333, [0.502, 0.694, 0.827]],
		[0.4, [0.502, 0.694, 0.827]],
		[0.467, [0.992, 0.706, 0.384]],
		[0.533, [0.702, 0.871, 0.412]],
		[0.6, [0.988, 0.804, 0.898]],
		[0.667, [0.851, 0.851, 0.851]],
		[0.733, [0.851, 0.851, 0.851]],
		[0.8, [0.737, 0.502, 0.741]],
		[0.867, [0.8, 0.922, 0.773]],
		[0.933, [1.0, 0.929, 0.435]],
		[1.0, [1.0, 0.929, 0.435]],
	],
	tab10: [
		[0.0, [0.122, 0.467, 0.706]],
		[0.067, [0.122, 0.467, 0.706]],
		[0.133, [1.0, 0.498, 0.055]],
		[0.2, [0.173, 0.627, 0.173]],
		[0.267, [0.173, 0.627, 0.173]],
		[0.333, [0.839, 0.153, 0.157]],
		[0.4, [0.58, 0.404, 0.741]],
		[0.467, [0.58, 0.404, 0.741]],
		[0.533, [0.549, 0.337, 0.294]],
		[0.6, [0.89, 0.467, 0.761]],
		[0.667, [0.89, 0.467, 0.761]],
		[0.733, [0.498, 0.498, 0.498]],
		[0.8, [0.737, 0.741, 0.133]],
		[0.867, [0.737, 0.741, 0.133]],
		[0.933, [0.09, 0.745, 0.812]],
		[1.0, [0.09, 0.745, 0.812]],
	],
	tab20: [
		[0.0, [0.122, 0.467, 0.706]],
		[0.067, [0.682, 0.78, 0.91]],
		[0.133, [1.0, 0.498, 0.055]],
		[0.2, [0.173, 0.627, 0.173]],
		[0.267, [0.596, 0.875, 0.541]],
		[0.333, [0.839, 0.153, 0.157]],
		[0.4, [0.58, 0.404, 0.741]],
		[0.467, [0.773, 0.69, 0.835]],
		[0.533, [0.549, 0.337, 0.294]],
		[0.6, [0.89, 0.467, 0.761]],
		[0.667, [0.969, 0.714, 0.824]],
		[0.733, [0.498, 0.498, 0.498]],
		[0.8, [0.737, 0.741, 0.133]],
		[0.867, [0.859, 0.859, 0.553]],
		[0.933, [0.09, 0.745, 0.812]],
		[1.0, [0.62, 0.855, 0.898]],
	],
	tab20b: [
		[0.0, [0.224, 0.231, 0.475]],
		[0.067, [0.322, 0.329, 0.639]],
		[0.133, [0.42, 0.431, 0.812]],
		[0.2, [0.388, 0.475, 0.224]],
		[0.267, [0.549, 0.635, 0.322]],
		[0.333, [0.71, 0.812, 0.42]],
		[0.4, [0.549, 0.427, 0.192]],
		[0.467, [0.741, 0.62, 0.224]],
		[0.533, [0.906, 0.729, 0.322]],
		[0.6, [0.518, 0.235, 0.224]],
		[0.667, [0.678, 0.286, 0.29]],
		[0.733, [0.839, 0.38, 0.42]],
		[0.8, [0.482, 0.255, 0.451]],
		[0.867, [0.647, 0.318, 0.58]],
		[0.933, [0.808, 0.427, 0.741]],
		[1.0, [0.871, 0.62, 0.839]],
	],
	tab20c: [
		[0.0, [0.192, 0.51, 0.741]],
		[0.067, [0.42, 0.682, 0.839]],
		[0.133, [0.62, 0.792, 0.882]],
		[0.2, [0.902, 0.333, 0.051]],
		[0.267, [0.992, 0.553, 0.235]],
		[0.333, [0.992, 0.682, 0.42]],
		[0.4, [0.192, 0.639, 0.329]],
		[0.467, [0.455, 0.769, 0.463]],
		[0.533, [0.631, 0.851, 0.608]],
		[0.6, [0.459, 0.42, 0.694]],
		[0.667, [0.62, 0.604, 0.784]],
		[0.733, [0.737, 0.741, 0.863]],
		[0.8, [0.388, 0.388, 0.388]],
		[0.867, [0.588, 0.588, 0.588]],
		[0.933, [0.741, 0.741, 0.741]],
		[1.0, [0.851, 0.851, 0.851]],
	],
};

export { colormaps };
