import * as Highcharts from "highcharts";
import { Offcanvas } from 'bootstrap';

import { Aeroval } from "../types/global";
declare var aeroval: Aeroval;

import { discreteColorscale, continuousColorscale } from "../components/colorscale";

import chartsStyle from '../../public/config/charts-style.json';


export function removeActiveClass() {
	// Function to remove 'active' class from all nav links
	const navLinks = document.querySelectorAll(".nav-link");
	navLinks.forEach((link) => {
		link.classList.remove("active");
	});
}

export function calcDist(stationLat: number, stationLon: number, lats: number[], longitudes: number[]): number {
	//process distance from coordinates to aeroval.station
	var prevD2 = 1e9;
	var closestIndex = 0;
	for (let i: number = 0; i < lats.length; i++) {
		var d2 = Math.pow(stationLat - lats[i], 2) + Math.pow(stationLon - longitudes[i], 2);
		if (d2 < prevD2) {
			closestIndex = i;
			prevD2 = d2;
		}
	}
	return closestIndex;
}

export function monthsInSeason(season: Aeroval["season"]): number[] {
	const MIS = {
		All: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		DJF: [12, 1, 2],
		MAM: [3, 4, 5],
		JJA: [6, 7, 8],
		SON: [9, 10, 11],
	};
	return MIS[season];
}

export function getLowerPowerOfTen(num: number): number | undefined {
	const powers = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	for (const power of powers) {
		if (num * Math.pow(10, power) >= 1) {
			return Math.pow(10, -power);
		}
	}
	return;
}

export function standardDeviation(values: number[]): number | undefined {
	const avg = average(values);
	var stdDev: number | undefined = undefined;

	if (!avg) return;

	const squareDiffs = values.map(function (value) {
		const diff = value - avg;
		const sqrDiff = diff * diff;
		return sqrDiff;
	});

	const avgSquareDiff = average(squareDiffs);
	if (avgSquareDiff) {
		stdDev = Math.sqrt(avgSquareDiff);
	}
	return stdDev;
}

export function average(data: number[]): number | undefined {
	//remove all null values
	var data = data.filter(function (el) {
		return el != null;
	});

	var avg: number | undefined = undefined;
	if (data.length > 0) {
		var sum = data.reduce(function (sum, value) {
			return sum + value;
		}, 0);
		avg = sum / data.length;
	}
	return avg;
}

export function quantile(array: number[], q: number): number {
	const sorted = array.sort((a, b) => a - b);
	const pos = (sorted.length - 1) * q;
	const base = Math.floor(pos);
	const rest = pos - base;
	if (sorted[base + 1] !== undefined) {
		return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
	} else {
		return sorted[base];
	}
}

export function IQR(array: number[]): number {
	return quantile(array, 0.75) - quantile(array, 0.25);
}

export function interpolateLinearly(x: number, colorscale: [number, [number, number, number]][]): number[] {
	// Split values into four lists
	const xValues = [];
	const rValues = [];
	const gValues = [];
	const bValues = [];
	for (let XRGB of colorscale) {
		xValues.push(XRGB[0]);
		rValues.push(XRGB[1][0]);
		gValues.push(XRGB[1][1]);
		bValues.push(XRGB[1][2]);
	}
	var i = 1;
	while (xValues[i] < x) {
		i = i + 1;
	}
	i = i - 1;
	const width = Math.abs(xValues[i] - xValues[i + 1]);
	const scaling_factor = (x - xValues[i]) / width;
	// Get the new color values though interpolation
	const r = rValues[i] + scaling_factor * (rValues[i + 1] - rValues[i]);
	const g = gValues[i] + scaling_factor * (gValues[i + 1] - gValues[i]);
	const b = bValues[i] + scaling_factor * (bValues[i + 1] - bValues[i]);
	return [enforceBounds(r), enforceBounds(g), enforceBounds(b)];
}

interface LinearRegression {
	slope: number | null;
	intercept: number | null;
	r2: number | null;
}

export function linearRegression(y: number[], x: number[]): LinearRegression {
	/*http://trentrichardson.com/2010/04/06/compute-linear-regressions-in-javascript/*/
	var lr: LinearRegression = {
		slope: null,
		intercept: null,
		r2: null,
	};

	var n = y.length;
	var sum_x = 0;
	var sum_y = 0;
	var sum_xy = 0;
	var sum_xx = 0;
	var sum_yy = 0;

	for (let i in y) {
		sum_x += x[i];
		sum_y += y[i];
		sum_xy += x[i] * y[i];
		sum_xx += x[i] * x[i];
		sum_yy += y[i] * y[i];
	}

	lr.slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
	lr.intercept = (sum_y - lr.slope * sum_x) / n;
	lr.r2 = Math.pow((n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)), 2);

	return lr;
}

export function enforceBounds(x: number): number {
	if (x < 0) {
		return 0;
	} else if (x > 1) {
		return 1;
	} else {
		return x;
	}
}

export function rgbToHex(r: number, g: number, b: number): string {
	return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export function componentToHex(c: number): string {
	const hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}

export function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

export function includesSubStr(array: string[], substring: string): boolean {
	// check if an array contains a substring
	for (let i in array) {
		if (array[i].includes(substring)) {
			return true;
		}
	}
	return false;
}

export function getMonth(i: number): string {
	return ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"][i];
}

// Define a custom interface that extends the Highcharts.Chart type
export function getChartById(id: string): Highcharts.Chart | undefined {
	for (const chart of Highcharts.charts) {
		if (chart && (chart as any).renderTo && (chart as any).renderTo.id === id) {
			return chart;
		}
	}
	return;
}

export function imageExists(image_url: string) {
	var http = new XMLHttpRequest();
	http.open("GET", image_url, false);
	http.send();
	return http.status != 404;
}

export function sleep(milliseconds: number) {
	const date = Date.now();
	let currentDate = null;
	do {
		currentDate = Date.now();
	} while (currentDate - date < milliseconds);
}

export function versionCompare(v1: string, v2: string): number {
	// returns +1 if v1 > v2, -1 if v1 < v2 and 0 if v1 == v2
	// Handle dev versions
	if (v1.includes(".dev")) {
		const parts1 = v1.split(".");
		const lastPart1 = parseInt(parts1.pop() || "0");
		const newVersion1 = parts1.concat([String(lastPart1 - 1)]).join(".");
		v1 = newVersion1;
	}

	const partsV1 = v1.split(".");
	const partsV2 = v2.split(".");

	for (let i = 0; i < Math.max(partsV1.length, partsV2.length); i++) {
		const num1 = parseInt(partsV1[i] || "0");
		const num2 = parseInt(partsV2[i] || "0");

		if (num1 > num2) {
			return 1;
		} else if (num1 < num2) {
			return -1;
		}
	}

	return 0; // Versions are equal
}

export function linkify(inputText: string): string {
	var replacedText, replacePattern1, replacePattern2, replacePattern3;

	//URLs starting with http://, https://, or ftp://
	replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
	replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

	//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
	replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
	replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

	//Change email addresses to mailto:: links.
	replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
	replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

	return replacedText;
}

export function reflow(): void {
	// reflow highcharts
	for (let chart of Highcharts.charts) {
		chart?.reflow();
	}
}

export function capitalize(str: string): string | undefined {
	return str ? str.replace(/^\w/, (c) => c.toUpperCase()) : undefined;
}

export function todo(): void {
	console.warn("not implemented yet");
}

export function getKeyTime(time: string, season: string): string {
	return season == "All" ? `${time}-all` : `${time}-${season}`;
}

export function range(start: number, edge: number, step: number): number[] {
	// If only 1 number passed make it the edge and 0 the start
	if (arguments.length === 1) {
		edge = start;
		start = 0;
	}

	// Validate edge/start
	edge = edge || 0;
	step = step || 1;

	// Create array of numbers, stopping before the edge
	let array = [] as number[];
	for (array; (edge - start) * step > 0; start += step) {
		array.push(start);
	}
	return array;
}

export function clickOnLegendItem(item: Highcharts.Series) {

	// get chart style
	if (window.location.pathname.includes('evaluation')) {
		var chartStyle = chartsStyle["evaluation-ts"] as any;
	} else if (window.location.pathname.includes('intercomp')) {
		chartStyle = chartsStyle["intercomp-ts"]
	} else {
		chartStyle = chartsStyle["default"]
	}

	//loop over all charts. and switch visibilty of series according to their id.
	if (typeof aeroval.seriesVisibility == "undefined") {
		aeroval.seriesVisibility = {};
	}
	const itemChart = (item.chart as any).renderTo.id;
	const itemName = item.name;

	if (!itemChart) return;

	console.info("click on ", itemName, "in", itemChart);
	aeroval.seriesVisibility[itemName] = item.userOptions.visible ? item.userOptions.visible : false;

	if (!itemName.includes("All Models")) {
		//remove series from all charts except this one
		Highcharts.charts.forEach(function (chart) {
			if (chart && (chart as any).renderTo.id) {
				if ((chart as any).renderTo.id != itemChart) {
					//loop though all series of this chart
					const seriesArray = chart.series;
					for (const series of seriesArray) {
						if (series.name == itemName || series.userOptions.id == itemName || series.userOptions.id?.includes(`trend-${itemName}`) || series.userOptions.id?.includes(`trend-data-${itemName}`)) {
							series.update({ visible: aeroval.seriesVisibility[itemName] } as any, false);
						}
					}
					chart.redraw();
				}
			}
		});
	} else {
		const frequency = itemName.split("(")[1].split(")")[0];
		Highcharts.charts.forEach(function (chart) {
			if (chart && (chart as any).renderTo) {
				if ((chart as any).renderTo.id == itemChart) {
					//loop though all series of this chart
					const seriesArray = chart.series;
					for (const series of seriesArray) {
						if (series.userOptions.id?.includes('mod') && series.userOptions.id?.includes(frequency)) {
							series.update({ visible: aeroval.seriesVisibility[itemName] } as any, false);
						}
					}
					chart.redraw();
				}
			}
		});
	}

	// change style between primary and secondary frequencies
	if (item.userOptions.id) {
		const id = item.userOptions.id;
		if (id.includes("primary")) {
			// get same secondary line
			const secondaryId = id.replace("primary", "secondary");
			const secondaryItem = item.chart.get(secondaryId);
			if (secondaryItem) {
				// if primary item has been hidden, use primary style for secondary item
				// if primary item has been shown, use secondary style for secondary item
				//const evalStyle = getEvalStyle();
				if (item.visible === false) {
					if (id.includes("obs")) {
						secondaryItem.update({
							color: chartStyle[id]?.color,
							lineWidth: chartStyle['obs-primary']?.lineWidth,
							weight: 2
						} as any);
					} else if (id.includes("mod")) {
						secondaryItem.update({
							color: chartStyle['mod-primary']?.color,
							lineWidth: chartStyle['mod-primary']?.lineWidth,
							weight: 2
						} as any);
					}
				} else {
					if (id.includes("obs")) {
						secondaryItem.update({
							color: chartStyle['obs-secondary']?.color,
							lineWidth: chartStyle['obs-secondary']?.lineWidth,
							weight: 1
						} as any);
					} else if (id.includes("mod")) {
						secondaryItem.update({
							color: chartStyle['mod-secondary']?.color,
							lineWidth: chartStyle['mod-secondary']?.lineWidth,
							weight: 1
						} as any);
					}
				}
			}
		}
	}
}

export function updateQueryString(which: string, what: string): void {
	//prevents browser from storing history with each change:
	var url = new URL(window.location.href);
	url.searchParams.set(which, what);
	history.replaceState(null, "", url);
}

export function getQueryString(param: string): string | undefined {
	// Get the query string from the URL
	const queryStrings = window.location.search.substring(1);

	// Split the query string into key-value pairs
	const keyValuePairs = queryStrings.split("&");

	// Iterate over each key-value pair
	for (const pair of keyValuePairs) {
		// Split the pair into key and value
		const [key, value] = pair.split("=");

		// Decode the value and replace '+' with spaces
		if (typeof value !== "undefined") {
			const decodedValue = decodeURIComponent(value.replace(/\+/g, " "));
			// If the key matches the parameter, return the decoded value
			if (key === param) {
				return decodedValue;
			}
		}
	}
	return;
}

export function initAeroval(): void {
	if (!window.aeroval) {
		window.aeroval = {
			config: {},
			data: {},
			settings: {
				theme: "light",
				lineStyle: "spline",
				regionalStatisticsSingleModelColor: "theme",
			},
		};
	}
	aeroval.isMobile = isMobileMode();
}

export function getDiurnalAllKey(keys: string[]): "All" | "Annual" | undefined {
	for (let key of keys) {
		if (key === "All" || key === "Annual") {
			return key;
		}
	}
	return;
}

export function getOrderedFrequencies(): Aeroval["frequency"][] {
	const preferredOrder = ["yearly", "monthly", "weekly", "daily", "hourly"];
	var cfgFrequencies = aeroval.cfg.time_cfg.freqs as Aeroval["frequency"][];
	return cfgFrequencies.filter((frequency) => preferredOrder.includes(frequency)).sort((a, b) => preferredOrder.indexOf(a) - preferredOrder.indexOf(b));
}

export function getModels(): string[] {
	var models = Object.keys(aeroval.menu[aeroval.parameter.dir]["obs"][aeroval.observation][aeroval.layer]);
	//Exclude some models
	const includeModels = [];
	for (let model of models) {
		if (!aeroval.excludeModels.includes(model)) {
			includeModels.push(model);
		}
	}
	return includeModels;
}

export function getTSId(): string {
	if (window.location.pathname.includes('evaluation')) {
		return 'evaluation-ts'
	} else if (window.location.pathname.includes('intercomp')) {
		return 'intercomp-ts'
	} else {
		return ''
	}
}

export function getUnits(statisticDir: string = aeroval.statistic.dir, modelDir: string = aeroval.model.dir): string | undefined {
	const unitTS = aeroval?.data?.ts?.[modelDir]?.obs_unit;
	const statisticUnit = aeroval.statistics[statisticDir].unit
	var unit: string | undefined = undefined;
	unit = statisticUnit == '1' ? '' : `(${statisticUnit})`;
	if (statisticDir.includes('mean')) {
		unit = `(${unitTS})`;
	} else if (aeroval.statistics[statisticDir].unit === 'var') {
		unit = unitTS ? `(${unitTS})` : `(var)`
	}
	return unit;
}

export function updateAxisType(): void {
	document.body.style.cursor = "progress";
	if (aeroval.tsType == "regular") {
		if (aeroval.profile) {
			var profilesChart = getChartById("profiles");
		} else {
			var scatterplotChart = getChartById("scat");
		}
		var timeseriesChart = getChartById(getTSId());
	} else if (aeroval.tsType == "weekly") {
		var scatterplotChart = getChartById("scat");
		var timeseriesChart = getChartById(getTSId());
	}
	if (!timeseriesChart) return;

	const type = aeroval.axisType;
	if ((timeseriesChart.yAxis[0] as any).dataMin && (timeseriesChart.yAxis[0] as any).dataMax) {
		var min = Math.max(0, getLowerPowerOfTen((timeseriesChart.yAxis[0] as any).dataMin) as number);
		var max = (timeseriesChart.yAxis[0] as any).dataMax;
	} else {
		return;
	}

	if (aeroval.axisType == "linear") {
		min = 0;
		if (scatterplotChart) {
			scatterplotChart.xAxis[0].update(
				{
					softMin: 0,
					type,
				},
				false
			);
			scatterplotChart.yAxis[0].update(
				{
					softMin: 0,
					type,
				},
				false
			);
		} else if (profilesChart) {
			profilesChart.yAxis[0].update(
				{
					softMin: 0,
					type,
				},
				false
			);
		}
		timeseriesChart.yAxis[0].update(
			{
				min,
				type,
			},
			false
		);
	} else if (aeroval.axisType == "logarithmic") {
		if (scatterplotChart) {
			scatterplotChart.xAxis[0].update(
				{
					min,
					max,
					type,
				},
				false
			);
			scatterplotChart.yAxis[0].update(
				{
					min,
					max,
					type,
				},
				false
			);
		} else if (profilesChart) {
			profilesChart.yAxis[0].update(
				{
					min,
					max,
					type,
				},
				false
			);
		}
		timeseriesChart.yAxis[0].update(
			{
				min,
				type,
			},
			false
		);
	}

	// redraw
	timeseriesChart.redraw(false);
	if (scatterplotChart) {
		scatterplotChart.redraw(false);
	} else if (profilesChart) {
		profilesChart.redraw(false);
	}

	document.body.style.cursor = "default";
}

export function getModelStyle(model: string | null): Aeroval["modelStyle"][keyof Aeroval["modelStyle"]] | undefined {
	if (typeof aeroval.modelStyle == "undefined") {
		//first, try to open a models-style in the project directory
		//var url = `${window.API_ROOT}/models_style/${aeroval.project}/models-style.json`;
		// to be done: try first the experiment specific file
		const url = `${window.API_ROOT}/models_style/${aeroval.project}/${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
		fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				aeroval.modelStyle = data;
				if (model && aeroval.modelStyle[model]) {
					return aeroval.modelStyle[model];
				}
			})
			.catch((error) => {
				console.warn("There was a problem with the fetch operation:", error);
				//second, opens general file in includes
				var filename = "/config/models-style.json";
				fetch(filename)
					.then((response) => {
						return response.json();
					})
					.then((data) => {
						aeroval.modelStyle = data;
						if (model && aeroval.modelStyle[model]) {
							return aeroval.modelStyle[model];
						}
					})
					.catch((error) => {
						console.warn("There was a problem with the fetch operation:", error);
						return null;
					});
			});
	} else {
		if (model && aeroval.modelStyle[model]) {
			return aeroval.modelStyle[model];
		}
	}
	return;
}

export function highlightSeason(div: string) {
	const timeseriesChart = getChartById(div);
	if (!timeseriesChart) return;

	// highlight selected in time series
	const season = aeroval.season;
	const minDate = aeroval.dateMin; //new Date(aeroval.dateMin);
	const maxDate = aeroval.dateMax; //new Date(aeroval.dateMax);
	if (!minDate || !maxDate) return;

	//years in time series
	var years = [];
	for (let i = 0; i < maxDate.getFullYear() + 1 - minDate.getFullYear(); i++) {
		years.push(minDate.getFullYear() + 1 * i);
	}

	//for all years, create a band for selected season
	var plotBands = [];
	for (let year of years) {
		if (season == "DJF") {
			plotBands.push({
				color: "#F5F5F5",
				from: new Date(year - 1, 12 - 1, 1).valueOf(),
				to: new Date(year, 2 - 1, 28).valueOf(),
			});
		}
		if (season == "MAM") {
			plotBands.push({
				color: "#F5F5F5",
				from: new Date(year, 3 - 1, 1).valueOf(),
				to: new Date(year, 5 - 1, 31).valueOf(),
			});
		}
		if (season == "JJA") {
			plotBands.push({
				color: "#F5F5F5",
				from: new Date(year, 6 - 1, 1).valueOf(),
				to: new Date(year, 8 - 1, 31).valueOf(),
			});
		}
		if (season == "SON") {
			plotBands.push({
				color: "#F5F5F5",
				from: new Date(year, 9 - 1, 1).valueOf(),
				to: new Date(year, 11 - 1, 30).valueOf(),
			});
		}
	}

	aeroval.highlightedSeasons = plotBands;

	if (timeseriesChart) {
		// set extremes and bands
		timeseriesChart.axes[0].update(
			{
				min: minDate.valueOf(),
				max: maxDate.valueOf(),
				plotBands,
			},
			true
		);

		// set title
		var title = timeseriesChart.options?.title?.text?.split("(")[0] as string;
		if (aeroval.season != "All") {
			title += ` (${aeroval.season})`;
		}
		timeseriesChart.title.update(
			{
				text: title,
			},
			true
		);
	}
}

export function syncZoom(divs: string[], event: Highcharts.XAxisEventsOptions | Highcharts.AxisSetExtremesEventObject): void {
	for (const div of divs) {
		const chart = getChartById(div);
		if (!chart) break;
		const min = (event as any).min === null ? (event as any).dataMin : (event as any).min;
		const max = (event as any).min === null ? (event as any).dataMax : (event as any).max;
		if (!((chart as any).resetZoomButton instanceof Object)) {
			chart.showResetZoom();
		}
		chart.xAxis[0].setExtremes(min, max);
	}
}

export function reverseColmap(col: any[]): any[] {
	var loc = [];
	for (let i = col.length - 1; i >= 0; i--) {
		const rgb = col[i][1];
		const x = 1 - i / col.length;
		loc.push([x, [rgb[0], rgb[1], rgb[2]]]);
	}
	return loc;
}

export function colStat(value: number, scale: any[], statisticDir: string) {
	//default color
	//var color = "#a6a6a6";
	if (window.location.href.includes("/overall")) {
		return continuousColorscale(value, scale, statisticDir);
	} else {
		return discreteColorscale(value, scale, aeroval.parameter.dir, statisticDir);
	}
	// return color
}

export function getPyaerocomVersion(): string | null {
	if ("pyaerocom_version" in aeroval.cfg?.exp_info) {
		// the time series format has changed from pyaerocom 0.13.1
		return aeroval.cfg.exp_info.pyaerocom_version;
	} else {
		return null;
	}
}

export function highchartsPalette(i: number): string {
	const colors = Highcharts.getOptions().colors;
	if (colors) {
		return colors[i % colors.length] as string;
	} else {
		return "rgba(125,125,125,1)";
	}
}

export function isMobileMode() {
	return window.innerWidth <= 768;
}

export function getTimeResolution(timestamps: number[]): undefined | 'hourly' | 'daily' | 'monthly' | 'yearly' {
	if (timestamps.length < 2) {
		console.warn('time array is too short for determining a time resolution')
		return undefined;
	}
	const differences = [];

	// Calculate differences between consecutive timestamps
	for (let i = 1; i < timestamps.length; i++) {
		differences.push(timestamps[i] - timestamps[i - 1]);
	}

	// Get the first time difference for comparison
	const avgDifference = differences.reduce((a, b) => a + b) / differences.length;

	// Define time thresholds
	//const oneHour = 60 * 60 * 1000;      // 3600000 ms
	const oneDay = 24 * 60 * 60 * 1000;  // 86400000 ms
	const oneMonth = 30 * oneDay;        // ~2592000000 ms
	const oneYear = 365 * oneDay;        // ~31536000000 ms

	// Determine resolution based on the average difference
	if (avgDifference < oneDay) {
		return "hourly";
	} else if (avgDifference < oneMonth) {
		return "daily";
	} else if (avgDifference < oneYear) {
		return "monthly";
	} else {
		return "yearly";
	}
}

export function getCoarsestFrequency(frequencies: Aeroval['frequency'][]): Aeroval['frequency'] {
	if (frequencies.includes('yearly')) {
		return 'yearly'
	} else if (frequencies.includes('monthly')) {
		return 'monthly'
	} else if (frequencies.includes('weekly')) {
		return 'weekly'
	} else if (frequencies.includes('daily')) {
		return 'daily'
	} else {
		return 'hourly'
	}
}


export function filterFrequencies(frequencies: Aeroval['frequency'][], frequency: Aeroval['frequency']): Aeroval['frequency'][] {
	var filteredFrequencies = frequencies
	// adjust list of available frequencies to the selected frequency
	const indexSelectedFrequency = frequencies.indexOf(frequency)
	if (frequencies.length > 2 && indexSelectedFrequency != -1) {
		filteredFrequencies = frequencies.slice(indexSelectedFrequency)
	}
	// if ends up with only one frequency, takes a coarser one
	if (indexSelectedFrequency > 0 && filteredFrequencies.length === 1) {
		filteredFrequencies.unshift(frequencies[indexSelectedFrequency - 1])
	}
	return filteredFrequencies
}

declare global {
	interface Window {
		API_ROOT: string;
		DATA_PATH: string;
	}
}

export function initAPI(): void {
	const version = "0.2.1"
	if (window.location.host.startsWith("localhost")) {
		window.API_ROOT = `http://127.0.0.1:9000/api/${version}`;
	} else if (window.location.host.startsWith("aeroval-test.met.no")) {
		window.API_ROOT = `https://api.aeroval-test.met.no/api/${version}`;
	} else {
		window.API_ROOT = `https://api.aeroval.met.no/api/${version}`;
	}
	window.DATA_PATH = window.location.pathname.split("/pages")[0];
	if (window.DATA_PATH.indexOf("/") != -1) {
		window.DATA_PATH = window.DATA_PATH.split("/")[1];
	}
}
type whichAxis = "x" | "y";

export function setManualExtremes(chart: Highcharts.Chart, which: whichAxis[]) {
	for (const whichAxis of which) {
		const thisAxis = (chart as any)[`${whichAxis}Axis`][0];
		const axisTitle = (thisAxis as any).axisTitle.element;
		axisTitle.style.cursor = "pointer";

		const offcanvasId = `${whichAxis}${chart.container.id}AxisOffcanvas`;
		var step = getLowerPowerOfTen(thisAxis.tickInterval)

		// insures the step is small
		if (step) {
			step /= 10
		}

		// Create offcanvas content
		const offcanvasContent = `
            <div class="offcanvas offcanvas-end" tabindex="-1" id="${offcanvasId}" aria-labelledby="${offcanvasId}Label">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="${offcanvasId}Label">${whichAxis}Axis configuration</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <form id="${offcanvasId}Form">
                        <div class="mb-3">
                            <label for="${offcanvasId}MinAxis" class="form-label">Min</label>
                            <input type="number" class="form-control" id="${offcanvasId}MinAxisInput" value="${thisAxis.min}" step="${step}"/>
                        </div>
                        <div class="mb-3">
                            <label for="${offcanvasId}MaxAxis" class="form-label">Max</label>
                            <input type="number" class="form-control" id="${offcanvasId}MaxAxisInput" value="${thisAxis.max}" step="${step}"/>
                        </div>
                        <button type="submit" class="btn btn-primary">Update chart</button>
                    </form>
                </div>
            </div>
        `;

		// Create offcanvas element
		const offcanvasElement = document.createElement('div');
		offcanvasElement.innerHTML = offcanvasContent;
		document.body.appendChild(offcanvasElement);

		// Define event listener inside loop
		axisTitle.addEventListener("click", (function (offcanvasId) {
			return function () {
				const canvasElement = document.getElementById(offcanvasId);
				if (canvasElement instanceof HTMLElement) {
					const offcanvas = new Offcanvas(canvasElement);
					offcanvas.show();
				}
			};
		})(offcanvasId));

		// Add submit event listener to the form
		const form = document.getElementById(`${offcanvasId}Form`);
		if (!form) continue;

		form.addEventListener('submit', function (event) {
			event.preventDefault(); // Prevent default form submission behavior

			// Get input elements
			const minInput = document.querySelector(`#${offcanvasId}MinAxisInput`) as HTMLInputElement;
			const maxInput = document.querySelector(`#${offcanvasId}MaxAxisInput`) as HTMLInputElement;

			// Get min and max values
			const min = parseFloat(minInput.value);
			const max = parseFloat(maxInput.value);

			// Update axis only if min or max values are valid
			if (!isNaN(min) || !isNaN(max)) {
				const updateOptions: Highcharts.AxisOptions = {};
				if (!isNaN(min)) updateOptions.min = min;
				if (!isNaN(min)) updateOptions.softMin = undefined;
				if (!isNaN(max)) updateOptions.max = max;
				thisAxis.update(updateOptions, true);
				// Add option once again
				setManualExtremes(chart, which);
			}
		});
	}
}

import { HMTSData } from '../types/data';
export function compactHMTSData(hmTSData: HMTSData): any {
	let statKeysSet = new Set<string>();
	let timeKeysSet = new Set<string>();

	// Collect all unique statistic names and time keys
	for (const obsVar of Object.values(hmTSData)) {
		for (const network of Object.values(obsVar)) {
			for (const layer of Object.values(network)) {
				for (const model of Object.values(layer)) {
					for (const modVar of Object.values(model)) {
						for (const region of Object.values(modVar)) {
							for (const time of Object.keys(region)) {
								timeKeysSet.add(time);
								for (const stat in region[time]) {
									statKeysSet.add(stat);
								}
							}
						}
					}
				}
			}
		}
	}

	// Sort keys for consistency
	const statKeys = Array.from(statKeysSet).sort();
	const timeKeys = Array.from(timeKeysSet).sort();

	// Transform HMTSData into a compact format
	let compactData: any["data"] = {};

	for (const [obsVarKey, obsVar] of Object.entries(hmTSData)) {
		compactData[obsVarKey] = {};
		for (const [networkKey, network] of Object.entries(obsVar)) {
			compactData[obsVarKey][networkKey] = {};
			for (const [layerKey, layer] of Object.entries(network)) {
				compactData[obsVarKey][networkKey][layerKey] = {};

				for (const [modelKey, model] of Object.entries(layer)) {
					compactData[obsVarKey][networkKey][layerKey][modelKey] = {};

					for (const [modVarKey, modVar] of Object.entries(model)) {
						compactData[obsVarKey][networkKey][layerKey][modelKey][modVarKey] = {};

						for (const [regionKey, region] of Object.entries(modVar)) {
							let regionData: number[][] = [];

							for (const timeKey of timeKeys) {
								const timeStats = region[timeKey] || {};
								regionData.push(
									statKeys.map(stat => {
										const value = timeStats[stat];
										return typeof value === "number" ? Number(value.toFixed(3)) : 0;
									})
								);
							}
							compactData[obsVarKey][networkKey][layerKey][modelKey][modVarKey][regionKey] =
								regionData;
						}
					}
				}
			}
		}
	}

	return { stat_keys: statKeys, time_keys: timeKeys, data: compactData };
}

export async function getConfig(filename: string): Promise<any> {
	const preUrl = window.location.href.split('pages')[0]
	const url = `${preUrl}config/${filename}`;
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`Request Failed: ${response.statusText}`);
		}
		return await response.json();
	} catch (error) {
		console.error("Request Failed:", error);
	}
}
