import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faFilePdf);

const filePdf = icon({ prefix: 'fas', iconName: 'file-pdf' });

import * as tocbot from 'tocbot';
import QRCode from "qrcode";
import * as Highcharts from 'highcharts';
import '../components/highcharts-options'

import * as main from './main';
import * as utils from '../utils';

import { getStatisticsTimeSeries } from '../components/charts/time-series-statistics';
import { getForecast } from '../components/charts/time-series-forecast';
import { initTargets, plotTargetCircle, plotTargetBars } from '../components/charts/targets'
import { getMapData, plotMap } from '../components/charts/map'

import camsFrontpageHeader from '../../public/reports/cams-template/CAMS-frontpage-header.png'
import camsFrontpageFooter from '../../public/reports/cams-template/CAMS-frontpage-footer.png'
//import camsHeader from '../../public/reports/cams-template/CAMS-header.png'
//import aerovalLogo from '../assets/images/logo/logo.png'

declare var MathJax: any;

// import interfaces and extend window variable
import { Aeroval } from '../types/global';
import '../styles/reports.css'

// Extend the Globals interface
interface ExtendedGlobals extends Aeroval {
	report: any;
	stats_report: {
		nTables: number;
		nFigures: number;
		nSection: number;
		nSectionFigures: number;
		nSubsection: number;
		nSubsubsection: number;
	};
	startTime: Date;
	endTime: Date;
	formulaWidth: any;
}
declare var aeroval: ExtendedGlobals;

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/reports')) {
		init();
	}
});

function init() {
	console.log('initialize report page');
	//check if any queries
	aeroval.seriesVisibility = {};
	if (window.location.href.includes('cams2-82')) {
		aeroval.menuButtons = ['model', 'region', 'time-no_seasons-no_freqs'];
	} else if (window.location.href.includes('cams2-83')) {
		aeroval.menuButtons = ['model', 'region', 'time-no_all-no_freqs-no_periods'];
	}

	main.getQueryStrings();
	main.getExperiments(true, false, false);
	main.updateURLs('project', aeroval.project);
}

export async function callbackMenu() {
	makeDivs();
}

function updateTime() {
	updateTimeSeries();
}

export function update(which: string, what: string) {
	console.info('update ', which, 'with ', what);
	// we set the following array to zero length as the export all gets all the charts from this array
	Highcharts.charts.length = 0;

	if (which == 'mean_and_statistic' || which == 'statistic' || which == 'forecast_statistic') {
		aeroval.statistic = aeroval.statistics[what];
		aeroval.statistic.dir = what;
		if (what == 'data_mean') {
			aeroval.statistic.name = 'Mean';
			aeroval.statistic.longname = 'Mean (Obs and Model)';
		}
		main.topMenu(true, true);
	}
	if (which == 'region') {
		aeroval[which] = what;
		main.topMenu(true, true);
	}
	if (which == 'experiment') {
		aeroval.experiment = aeroval.experiments[what];
		aeroval.experiment.name = what;
		main.getExperiments(true);
		utils.updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'obsType') {
		aeroval[which] = what;
		main.topMenu(true, true);
	}
	if (which == 'frequency') {
		aeroval[which] = what as Aeroval['frequency'];
		utils.updateQueryString('stats', what);
		main.updateURLs('stats', what);
		main.topMenu(true, true);
	}
	if (which.includes('time')) {
		aeroval.time = what;
		main.topMenu(true, true);
		main.getExtremeDates();
	}
	if (which == 'year') {
		aeroval.time = what;
		main.showMenu(which, what);
		main.getExtremeDates();
		updateTime();
	}
	if (which == 'season') {
		aeroval[which] = what as Aeroval['season'];
		main.topMenu(true, true);
	}
	if (which == 'model') {
		// remove some data
		delete aeroval.data.hm;

		aeroval.model = {
			dir: what,
			name: what,
			var: aeroval.modVars[what],
		};
		utils.updateQueryString(which, what);
		main.topMenu(true, true);
	}
}

async function makeDivs() {
	//create divs
	var h = '';
	if (aeroval.project == 'cams2-83' || aeroval.project == 'cams2-82') {
		h += "<div id='main-content'>";
		if (!aeroval.isMobile) {
			var h = `<div class="alert alert-dismissible alert-light fade show my-2 w-100" role="alert" style="display: inline-flex; justify-content: space-between; align-items: center;">
				<div>
					Download PDF document </br> <i>We recommend using Google Chrome with the "background graphics" option activated</i>.
				</div>
				<div>
					<button id="print-pdf" type="button-sm" class="btn btn-primary px-2" title="print" disabled>${filePdf.html}</button>
				</div>
			</div>
			</div>`;
		}

		// add hidden images for report
		//h += `<img id="cams-frontpage-header" src="${camsFrontpageHeader}" style="display: none">`;
		//h += `<img id="cams-frontpage-footer" src="${camsFrontpageFooter}" style="display: none">`;
		//h += `<img id="cams-header" src="${camsHeader}" style="display: none">`;
		//h += `<img id="aeroval-logo" src="${aerovalLogo}" style="display: none">`;

		// content container
		h += '<div id="report-container"></div>';
		h += '</div>';
	} else {
		utils.todo();
	}
	const main = document.getElementById('main');
	if (main) {
		main.innerHTML = h;
	}

	const reportContent = await loadReportContent();
	const linkedReportContent = await makeLinks(reportContent);
	makeReport(linkedReportContent);
	addContributors(linkedReportContent)
	addTOC(linkedReportContent)
	addCAMSCoverPage(linkedReportContent)
	addQRCode()
	//addCAMSHeader()

	// set up button actions
	const printPDFButton = document.getElementById('print-pdf');
	if (printPDFButton) {
		printPDFButton.addEventListener('click', function () {
			print()
		});
	}

	window.addEventListener("beforeprint", () => {
		beforePrint(linkedReportContent)
	});

	window.addEventListener("afterprint", () => {
		afterPrint()
	});

}

function addQRCode() {
	// Create the header div element
	const qrCodeDiv = document.createElement('div');
	qrCodeDiv.id = 'qr-code'
	var h = `<canvas id="qr-code-canvas"></canvas>`
	qrCodeDiv.innerHTML = h

	const reportContainerElement = document.getElementById('report-container');
	reportContainerElement!.appendChild(qrCodeDiv);

	const qrCodeCanvas = document.getElementById('qr-code-canvas')
	const qrText = window.location.href; // Example: Current page URL
	QRCode.toCanvas(qrCodeCanvas, qrText, {
		width: 100, // Adjust size as needed
		margin: 1
	});

}

function addContributors(reportContent: any) {
	if (!(Object.keys(reportContent).includes('contributors'))) return

	const contributorsReport = reportContent['contributors']

	// Create the header div element
	const contributorsDiv = document.createElement('div');
	contributorsDiv.id = 'contributors'
	var h = `<h2 class="break-before">Contributors</h2>
		<ul>`
	for (let institution in contributorsReport) {
		h += `<li> ${institution} </li>
			<ul>`
		const team = contributorsReport[institution]
		for (const person of team) {
			h += `<li>${person}</li>`
		}
		h += '</ul>'
	}
	h += '</ul>'
	contributorsDiv.innerHTML = h

	const reportContainerElement = document.getElementById('report-container');
	const firstReportElement = reportContainerElement?.firstChild;
	if (reportContainerElement && firstReportElement) {
		reportContainerElement.insertBefore(contributorsDiv, firstReportElement);
	}
}

function addTOC(reportContent: any) {
	if (!(Object.keys(reportContent).includes('table-of-contents'))) return

	const tocReport = reportContent['table-of-contents']

	// Create the header div element
	const tocDiv = document.createElement('div');
	tocDiv.id = 'toc'
	tocDiv.innerHTML = `
		<h2 class="break-before js-toc-ignore">${tocReport.title}</h2>
		<div id="js-toc"></div>
	`

	// Append the toc div to the document body
	const targetElement = document.getElementById(`section-${tocReport.after.replaceAll(' ', '-')}`)
	if (targetElement && targetElement.parentNode) {
		targetElement.parentNode.insertBefore(tocDiv, targetElement.nextSibling);
	}

	(tocbot as any).init({
		tocSelector: '#js-toc',
		ignoreSelector: '.js-toc-ignore',
		contentSelector: '#report-container',
		headingSelector: 'h2, h3, h4, h5',
		orderedList: false
	});

}

function beforePrint(reportContent: any) {
	const chartsConfig = {
		ts: {
			width: 740,
		},
		fcst: {
			width: 740
		},
		map: {
			width: 600
		},
		'target-circle': {
			width: 400
		},
		'target-bar': {
			width: 620
		},
		default: {
			width: 500
		}
	} as any
	var chartConfig = chartsConfig.default as any
	for (let chart of Highcharts.charts) {
		for (let key in chartsConfig) {
			var which = (chart as any).renderTo.id as string
			if (which.startsWith('trgt')) {
				which = (chart as any).renderTo.classList[0]
			}
			if (which.startsWith(key)) {
				chartConfig = chartsConfig[key]
				continue
			}
		}
		chart?.update({
			chart: {
				animation: false,
				plotShadow: false,
				width: chartConfig.width
			}
		}, true)
	}

	// change document title
	document.title = reportContent.ref
}

function afterPrint() {
	for (let chart of Highcharts.charts) {
		chart?.reflow()
	}
}

/*function addCAMSHeader() {
	// Create the header div element
	const headerDiv = document.createElement('div');
	headerDiv.className = 'report-header';

	// Create the img element
	headerDiv.innerHTML = 'Copernicus Atmosphere Monitoring Service 2'

	// Append the header div to the document body
	document.body.appendChild(headerDiv);
}*/

function addCAMSCoverPage(reportContent: any) {
	// Create the header div element
	const coverDiv = document.createElement('div');
	coverDiv.id = 'cover-page'
	coverDiv.className = 'break-after'

	// Create the img element
	coverDiv.innerHTML = `
		<div class="cover-header">
			<img src="${camsFrontpageHeader}" alt="Header Image">
		</div>
		<div class="cover-content">
			<h1>${reportContent.title}</h1>
			<h2 style="color: #5acddf">${reportContent.subtitle}</h2>
			<div id="cover-info">
				<div>Issue by: ${reportContent.issued_by}</div>
				<div>Date: ${reportContent.date}</div>
				<div>Ref: ${reportContent.ref}</div>
			</div>
		</div>
		<div class="cover-footer">
			<img src="${camsFrontpageFooter}" alt="Footer Image">
		</div>`

	// Append the header div to the document body
	//const mainElement = document.getElementById('main')
	//mainElement!.appendChild(coverDiv);
	document.body.append(coverDiv);
}

async function loadReportContent(): Promise<ExtendedGlobals['reports']> {
	var url: string | undefined = undefined;
	var title: string | undefined = undefined
	if (aeroval.project == 'cams2-83') {
		title = `EQC-report_${aeroval.model.dir}_${aeroval.season}${aeroval.time}`
		url = `${window.API_ROOT}/file/reports/${aeroval.project}/${aeroval.experiment.name}/${title}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}.json`
	} else if (aeroval.project == 'cams2-82') {
		title = `QL-report_${aeroval.model.dir}_${aeroval.time}`
		url = `${window.API_ROOT}/reports/${aeroval.project}/${aeroval.experiment.name}/${title}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`
	}
	if (!url || !title) return;

	// load report
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json'
			}
		});

		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const data = await response.json();

		// Insert model name
		let strData = JSON.stringify(data);
		strData = strData.replaceAll('*model*', aeroval.model.name);
		strData = strData.replaceAll('*time*', `${aeroval.season}${aeroval.time}`);

		// Replace special characters
		const specialCharacters: { [key: string]: string } = {
			'↗': "<svg fill='#0d6efd' height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z'/></svg>",
			'↘': "<svg fill='#dc3545' height='16' width='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z'/></svg>",
		};

		for (const specialCharacter in specialCharacters) {
			strData = strData.replaceAll(specialCharacter, specialCharacters[specialCharacter]);
		}

		return JSON.parse(strData) as ExtendedGlobals['report'];
	} catch (error) {
		console.error('There was a problem with the fetch operation:', error);
		const h = '<div class="alert alert-warning" role="alert" style="margin: 50px auto">No report is available for the current selection</div>';
		// Send content to div
		const innerDiv = document.getElementById('report-container');
		if (innerDiv) {
			innerDiv.innerHTML = h;
		}
	}
}

async function makeLinks(content: any): Promise<any> {
	aeroval.report = content;
	// loop through each section
	for (const i in content.sections) {
		// loop through content
		for (const j in content.sections[i].content) {
			// linkify all keys
			for (const key in content.sections[i].content[j]) {
				if (key != 'options') {
					content.sections[i].content[j][key] = utils.linkify(content.sections[i].content[j][key]);
				}
			}
		}
	}
	return content
}

async function makeReport(content: any) {
	// global count of figures for later reference
	aeroval.stats_report = {
		nTables: 0,
		nFigures: 0,
		nSection: 0,
		nSectionFigures: 0,
		nSubsection: 0,
		nSubsubsection: 0,
	};

	var h = '';
	for (const section of content.sections) {
		const sectionTitle = section.title.split('# ')[1] ? section.title.split('# ')[1] : section.title
		const sectionId = sectionTitle.replaceAll(' ', '-')
		h += `<div id="section-${sectionId}">`
		// add title
		if (section.title.startsWith('# ')) {
			aeroval.stats_report.nSection++;
			aeroval.stats_report.nSectionFigures = 0;
			aeroval.stats_report.nSubsection = 0;
			aeroval.stats_report.nSubsubsection = 0;
			var numerotation = String(aeroval.stats_report.nSection);
			// default: break-before
			h += `<h2 class="break-before" id="${sectionId}">${numerotation} ${sectionTitle}</h2>`
		} else if (section.title.startsWith('## ')) {
			aeroval.stats_report.nSubsection++;
			aeroval.stats_report.nSubsubsection = 0;
			numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSubsection}`;
			h += ('pageBreak' in section && section.pageBreak == 'before')
				? `<h3 class="break-before" id="${sectionId}">${numerotation} ${sectionTitle}</h3>`
				: `<h3 class="no-break-before" id="${sectionId}">${numerotation} ${sectionTitle}</h3>`
		} else if (section.title.startsWith('### ')) {
			aeroval.stats_report.nSubsubsection++;
			numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSubsection}.${aeroval.stats_report.nSubsubsection}`;
			h += ('pageBreak' in section && section.pageBreak == 'before')
				? `<h4 class="break-before" id="${sectionId}">${numerotation} ${sectionTitle}</h4>`
				: `<h4 class="no-break-before" id="${sectionId}">${numerotation} ${sectionTitle}</h4>`
		} else {
			// if no number
			h += `<h2 class="break-before" id="${sectionId}">${section.title}</h2>`
		}
		// add content
		if (section.content) {
			for (let content of section.content) {
				h += addContent(content);
			}
		}
		h += '</div>';
	}

	// add figures ids
	h = addFiguresRefById(h)

	// send content to div
	const innerDiv = document.getElementById('report-container');
	if (innerDiv) {
		const contentDiv = document.createElement('div');
		contentDiv.className = 'report-container';
		contentDiv.innerHTML = h;
		innerDiv.appendChild(contentDiv);
	}

	// load charts
	for (let section of content.sections) {
		if (section.content) {
			for (let content of section.content) {
				if (content.type === 'chart') {
					await loadChart(content.id, content.options);
				}
			}
		}
	}

	const dwnldButton = document.getElementById('dwnld-pdf')
	if (dwnldButton instanceof HTMLButtonElement) {
		dwnldButton.disabled = false
	}

	const printButton = document.getElementById('print-pdf')
	if (printButton instanceof HTMLButtonElement) {
		printButton.disabled = false
	}

}

function addFiguresRefById(content: string): string {
	// check in the content for dif[#]
	var newContent = content
	var figRefInContent = true

	while (figRefInContent) {
		//work with substract of text
		const from = newContent.indexOf('fig[#')
		if (from == -1) break;

		var partText = newContent.substring(from)
		const to = from + partText.indexOf(']') + 1
		partText = newContent.substring(from, to)

		const figId = partText.split('[#')[1].split(']')[0]
		const figNum = newContent.split(`caption-${figId}`)[1]?.split('<b>')[1]?.split('</b>')[0]
		newContent = replaceBetween(newContent, from, to, `<a href="#${figId}">${figNum}</a>`)

		// check if needs to continue
		figRefInContent = newContent.includes('fig[#')
	}
	return newContent
}

function replaceBetween(origin: string, startIndex: number, endIndex: number, insertion: string): string {
	return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
}

function addContent(content: any): string | undefined {
	var html: string | undefined = undefined;
	if (content.type == 'chart') {
		html = `<div id="${content.id}" class="chart"></div>`;
		if (content.caption.length > 0) {
			aeroval.stats_report.nSectionFigures++;
			const contentTitle = content.title.endsWith('#') ? content.title.split('#')[0] : content.title
			const numerotation = content.title.endsWith('#') ? `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSectionFigures}` : ''
			html += `<div class="caption"><b>${contentTitle} ${numerotation}: </b>${content.caption}</div>`;
		}
	} else if (content.type == 'paragraph') {
		html = `<p>${content.text}</p>`;
	} else if (content.type == 'table') {
		html = `<div class="table-responsive">${content.text.replaceAll('<table', "<table class='table table-hover'")}</div>`;
		if (content.caption.length > 0) {
			aeroval.stats_report.nTables++;
			const contentTitle = content.title.endsWith('#') ? content.title.split('#')[0] : content.title
			const numerotation = content.title.endsWith('#') ? `${aeroval.stats_report.nTables}` : ''
			html += `<div class="caption"><b>${contentTitle} ${numerotation}: </b>${content.caption}</div>`;
		}
	} else if (content.type == 'figure') {
		aeroval.stats_report.nFigures++;
		const contentUrl = content.url.replaceAll('*time*', `${aeroval.season}${aeroval.time}`)
		const src = `${window.API_ROOT}/reports_images/${aeroval.project}/${aeroval.experiment.name}/${contentUrl}`;
		const id = content.id ? content.id : '';
		html = '<figure class="text-center" style="margin: 0px 0px 20px 0px;">';
		html += `<img crossorigin="anonymous" ref="img-ref-${aeroval.stats_report.nFigures}" id="${id}" class="img-static img-fluid mx-auto" src="${src}">`;
		if (content.caption.length > 0) {
			aeroval.stats_report.nSectionFigures++;
			html += `<figcaption `
			if (id) {
				html += `id="caption-${id}"`
			}
			if (content.title.endsWith('#')) {
				var numerotation = `${aeroval.stats_report.nSection}.${aeroval.stats_report.nSectionFigures}`;
				html += `class="caption"><b>${content.title.replace('#', numerotation)}</b>: ${content.caption}</figcaption>`;
			} else {
				html += `class="caption"><b>${content.title}</b>: ${content.caption}</figcaption>`;
			}
		}
		html += '</figure>';
	} else if (content.type == 'equation') {
		var equation = MathJax.tex2svg(content.text.replace(/\\\\/g, '\\').replaceAll('$$', ''));
		equation.firstChild.setAttribute('height', '60px');
		equation.firstChild.setAttribute('width', '100%');
		html = `<div class="formula-container">
			${equation.innerHTML}
		</div>`
		html = html.replace('<svg', '<svg class="formula"');
		// add formula class to svg
	} else if (content.type == 'reference') {
		html = `<p>
			${content.authors}, 
			<b>${content.title}</b>, 
			<i>${content.journal}</i>, 
			${content.url}, 
			${content.date}.
		</p>`
	} else {
		console.warn('unknown content type:', content.type);
	}

	return html;
}

async function loadChart(id: string, chartOptions: any = {}) {

	const type = id.split('_')[0];
	const observation = id.split('_')[1];
	const parameterDir = id.split('_')[2];
	const layer = id.split('_')[3];
	const statisticDir = id.split('_')[4];
	const modelDir = aeroval.model.dir
	const groupBy = 'None'
	const models = (aeroval.project == 'cams2-83' && modelDir != 'ENSEMBLE') ? [modelDir, 'ENSEMBLE'] : [modelDir]

	if (type.startsWith('ts')) {
		chartOptions = {
			height: 280,
			lineWidth: 2,
			markerRadius: 4,
			models: models,
			show_number_points: true
		};
		getStatisticsTimeSeries(aeroval.project, aeroval.experiment.name, aeroval.region, observation, parameterDir, layer, statisticDir, groupBy, true, id, chartOptions)
	} else if (type.startsWith('fcst')) {
		chartOptions = {
			height: 280,
			models: models
		};
		getForecast(aeroval.project, aeroval.experiment.name, aeroval.region, observation, parameterDir, layer, statisticDir, true, id, chartOptions);
	} else if (type.startsWith('trgt')) {
		const experimentName = type.startsWith('trgt-an') ? aeroval.experiment.name.replace('forecast', 'analysis') : aeroval.experiment.name
		const mapData = await getMapData(aeroval.project, experimentName, observation, parameterDir, layer, modelDir, parameterDir, aeroval.time, aeroval.frequency)
		if (!mapData) return
		initTargets(id, true, true)
		plotTargetCircle(mapData, observation, parameterDir, modelDir, id, chartOptions);
		plotTargetBars(mapData, observation, parameterDir, modelDir, id, chartOptions)
	} else if (type.startsWith('map')) {
		const mapData = await getMapData(aeroval.project, aeroval.experiment.name, observation, parameterDir, layer, modelDir, parameterDir, aeroval.time, aeroval.frequency)
		if (!mapData) return
		chartOptions = {
			markerRadius: 3
		};
		plotMap(mapData, parameterDir, statisticDir, id, chartOptions)
	}/*else if (type.startsWith('qrtr')) {
		loadHeatmapData(id, options, true);
	}*/ else {
		console.warn('unknown type chart:', type);
	}
}

function updateTimeSeries() {
	const y1 = (aeroval.time.indexOf('-') != -1) ? aeroval.time.split('-')[0] : aeroval.time
	const y2 = (aeroval.time.indexOf('-') != -1) ? aeroval.time.split('-')[1] : aeroval.time
	const chart = utils.getChartById('ts');
	if (chart) {
		chart.xAxis[0].update({
			min: new Date(parseFloat(y1), 0, 1).valueOf(),
			max: new Date(parseFloat(y2), 11, 31).valueOf(),
		});
		chart.setTitle({ text: `${aeroval.parameter.name} - ${aeroval.region} - ${aeroval.time}` });
	}
}
