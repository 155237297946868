// Import Highcharts modules
import * as Highcharts from 'highcharts';
import '../../highcharts-options';

import { getKeyTime, clickOnLegendItem, getModelStyle, highchartsPalette } from '../../../utils';

import chartsStyle from '../../../../public/config/charts-style.json';

import { Aeroval } from '../../../types/global';
import { ForecastData } from '../../../types/data';
declare var aeroval: Aeroval;

export function getForecast(project: string, experimentName: string, region: string, observation: string, parameterDir: string, layer: string, statisticDir: string, plot: boolean, div: string, options: any = {}) {
	const url = `${window.API_ROOT}/forecast/${project}/${experimentName}/${region}/${observation}/${parameterDir}/${layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			if (plot) {
				plotForecast(data, parameterDir, observation, layer, statisticDir, div, options);
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

export function plotForecast(data: ForecastData, parameterDir: string, observation: string, layer: string, statisticDir: string, div: string, chartOptions: any): void {
	const series = [] as any;
	const subData = data[parameterDir][observation][layer];
	const keyTime = getKeyTime(aeroval.time, aeroval.season);
	const models = (!chartOptions.models || chartOptions.models === 'all') ? Object.keys(subData) as string[] : chartOptions.models;

	var i = 0;
	for (const model of models) {
		const modVar = Object.keys(subData[model])[0];

		// set model style
		const highchartsColor = highchartsPalette(i);
		const modelStyle = getModelStyle(model);
		const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;
		const modelWeight = modelStyle?.weight ? modelStyle.weight : 1;
		const markerSizeModel = modelWeight * chartsStyle['frcst-ts']['mod'].markerSize * Number(aeroval.settings.lineWidthFactor);
		const lineWidthModel = modelWeight * chartsStyle['frcst-ts']['mod'].lineWidth * Number(aeroval.settings.lineWidthFactor);

		//here we overwrite the visibility
		const visible = typeof aeroval.seriesVisibility[model] != 'undefined' ? aeroval.seriesVisibility[model] : true

		series.push({
			data: subData[model][modVar][keyTime][statisticDir],
			color: colorModel,
			name: model,
			id: `mod-${model}`,
			visible: visible,
			lineWidth: lineWidthModel,
			marker: {
				radius: markerSizeModel,
			},
			weight: modelWeight
		});
		i++;
	}


	// set axis unit and title
	const axisUnit = aeroval.statistics[statisticDir].unit != '1' ? `(${aeroval.statistics[statisticDir].unit})` : ''
	const parameterName = aeroval.menu[parameterDir].name
	const titleText = aeroval.season == 'All' ? `${parameterName} - ${aeroval.region} - ${aeroval.time}` : `${parameterName} - ${aeroval.region} -${aeroval.time} (${aeroval.season})`
	const statisticName = aeroval.statistics[statisticDir].name

	var subtitle = observation;
	// if the frequency of the stats in the time series is different from the main freq, display the used frequency
	if ('stats_tseries_base_freq' in aeroval.cfg.statistics_opts && aeroval.cfg.statistics_opts.stats_tseries_base_freq != null) {
		if (aeroval.cfg.statistics_opts.stats_tseries_base_freq != aeroval.cfg.time_cfg.main_freq) {
			subtitle += ' (statistics computed from ' + aeroval.cfg.statistics_opts.stats_tseries_base_freq + ' values)';
		}
	}

	Highcharts.chart({
		chart: {
			renderTo: div,
			type: aeroval.settings.lineStyle,
			zooming: { type: 'x' },
			height: chartOptions && chartOptions.height ? chartOptions.height : 500,
			resetZoomButton: {
				position: {
					align: 'center',
				},
			},
		},
		title: {
			text: titleText,
		},
		subtitle: {
			text: subtitle,
		},
		exporting: {
			enabled: true,
		},
		tooltip: {
			shared: true,
			valueDecimals: 3,
			headerFormat: 'Hour ({point.point.x}) <br>',
		},
		yAxis: {
			title: {
				text: `Median of ${statisticName} ${axisUnit}`,
			},
			//softMin: 0,
		},
		xAxis: {
			//type: 'datetime',
			title: {
				text: 'Hour',
			},
			tickInterval: 6,
			plotLines: [
				{
					width: 3,
					value: 24,
					color: 'rgba(153, 153, 153, .25)',
				},
				{
					width: 3,
					value: 48,
					color: 'rgba(153, 153, 153, .25)',
				},
				{
					width: 3,
					value: 72,
					color: 'rgba(153, 153, 153, .25)',
				},
			],
			events: {
				setExtremes: function (e) {
					setZoomState(e);
				},
			},
		},
		legend: {
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			floating: false,
		},
		plotOptions: {
			series: {
				events: {
					legendItemClick: function () {
						clickOnLegendItem(this);
					},
				},
				states: {
					inactive: {
						enabled: false,
					},
				},
			},
		},
		series,
	});
}

function setZoomState(e: Highcharts.AxisSetExtremesEventObject) {
	if (e.max && e.min) {
		aeroval.zoom = {
			state: true,
			min: e.min,
			max: e.max,
		};
	} else {
		aeroval.zoom = {
			state: false,
			min: null as any,
			max: null as any,
		};
	}
}
