import * as Highcharts from "highcharts";

import { Aeroval } from "../../types/global";
declare var aeroval: Aeroval;

Highcharts.setOptions({
    chart: {
        backgroundColor: 'transparent',
        events: {
            fullscreenOpen: function () {
                // fullscreen background color
                const fullScreenBackgroundColor = aeroval.settings.theme == 'dark' ? '#282b34' : 'white';
                (this as any).update({
                    chart: {
                        backgroundColor: fullScreenBackgroundColor,
                    },
                });
            },
            fullscreenClose: function () {
                (this as any).update({
                    chart: {
                        backgroundColor: 'transparent',
                    },
                });
            }, load: function () {
                const chart = this;

                // Function to handle double-click on legend items
                function handleLegendItemDblClick(event: MouseEvent, series: Highcharts.Series) {
                    event.stopPropagation();

                    // reset series visibility
                    aeroval.seriesVisibility = {}

                    // Hide all series except the one that was double-clicked
                    chart.series.forEach(s => {
                        if (s !== series) {
                            s.setVisible(false, false);
                            aeroval.seriesVisibility[(s as any).options.name] = false
                        } else {
                            // Toggle visibility of the double-clicked series
                            s.setVisible(true, false);
                            aeroval.seriesVisibility[(s as any).options.name] = true
                        }
                    });

                    // Redraw the chart
                    chart.redraw();
                }

                // Add double-click event listeners to legend items
                chart.series.forEach(series => {
                    const legendItem = (series.legendItem as any)?.group.element;
                    if (legendItem) {
                        legendItem.addEventListener('dblclick', (event: MouseEvent) => handleLegendItemDblClick(event, series));
                    }
                });
            }
        }
    },
    credits: {
        enabled: false,
    },
    accessibility: {
        enabled: false,
    },
    exporting: {
        chartOptions: {
            chart: {
                events: {
                    load: function (this: any) {
                        if (this.resetZoomButton) {
                            this.resetZoomButton.hide();
                        }
                    }
                }
            }
        }
    },
    title: {
        useHTML: true
    },
    subtitle: {
        useHTML: true
    },
    yAxis: {
        title: {
            useHTML: true
        },
        labels: {
            useHTML: true
        }
    },
    xAxis: {
        title: {
            useHTML: true
        }
    }
});

if (window.location.href.includes('reports')) {
    Highcharts.setOptions({
        chart: {
            style: {
                fontSize: '1em'
            },
            reflow: true
        },
        title: {
            style: {
                fontSize: '0.9em'
            },
        },
        subtitle: {
            style: {
                fontSize: '0.7em'
            },
        },
        legend: {
            itemStyle: {
                fontSize: '0.6em'
            },
        },
        yAxis: {
            title: {
                style: {
                    fontSize: '0.7em'
                },
            },
        },
        xAxis: {
            title: {
                style: {
                    fontSize: '0.7em'
                },
            },
        },
    });
}